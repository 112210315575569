@import "../../utility/utility.styles.scss";

// container for search filters
// sf = search filter
.sf-container {
  width: 33.75rem;
  min-width: 33.75rem;
  padding: 6.4rem 1.6rem 6rem;
  overflow-x: hidden;
  min-height: calc(100vh - 6.4rem);
  border-right: 1px solid $shade-1;
  border-left: 1px solid $shade-1;
  border-bottom: 1px solid $shade-1;

  @media screen and (max-width: 1290px) {
    width: 30rem;
    min-width: 30rem;
  }

  @media screen and (max-width: 1210px) {
    width: 29rem;
    min-width: 29rem;
  }
  @media screen and (max-width: 1195px) {
    width: 25.8rem;
    min-width: 25.8rem;
    padding: 6.4rem 0rem 6rem;
  }
  @media screen and (max-width: 1163px) {
    display: none;
  }
}

.sf-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.8rem 3rem 1.6rem;
  color: $primary;
}

.sf-item {
  width: 100%;
  //   height: 68px;
  //   border: 1px solid black;
  padding: 16px 24px 16px 16px;
  // border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Avenir-Heavy;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: $gray-3;

  &:hover {
    background-color: $shade-2;
    border-radius: 4px;
  }
}

.sf-input-wrap {
  width: 100%;
  //   height: 90px;
  padding: 0px 24px 16px 16px;
  //   border: 1px solid black;
  background-color: $shade-2;
  margin-bottom: 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.active-sf-item {
  background-color: $shade-2;
  color: $primary;
  width: 100%;
  //   height: 68px;
  padding: 16px 24px 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Avenir-Heavy;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
}

.clearall-btn-wrap {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.sf-drawer-closer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  height: 4.8rem;
  padding: 0rem 1.6rem;
  margin-bottom: 1.6rem;
  border-bottom: 1px solid $shade-1;
}
