@import "../../utility//utility.styles.scss";

.ut-container {
  margin: 0 auto;
  width: 558px;
  min-height: 500px;
  max-width: 100%;

  @media screen and (max-width: 732px) {
    h2 {
      text-align: center;
    }
  }
}

.user-ticket-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  border-radius: 4px;
  background-color: $colour-white;

  // @media screen and (max-width: 732px) {
  //   margin: 4rem 1rem 4rem 1rem;
  //   padding: 0 1.5rem 0 1.5rem;
  // }
}

.user-ticket-card {
  min-width: 55.8rem;
  height: 27.3rem;
  border-radius: 0.8rem;
  margin-bottom: 2.8rem;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(52, 40, 40, 0.08);
  cursor: pointer;
  overflow-wrap: break-word;
  background-color: #fff;
  &:hover {
    background-color: $background;
  }
  @media screen and (max-width: 732px) {
    min-width: auto;
    height: auto;
  }
}

.ticket-avenir-roman-18 {
  font-family: Avenir-Roman;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
}

.user-ticket-btn {
  background: $primary;
  color: white;
  height: 4.8rem;
}

.user-ticket-resend {
  border-radius: 0 0rem 0rem 0.8rem;
}
.user-ticket-print {
  border-radius: 0 0rem 0.8rem 0rem;
}

.ut-modal-wrap {
  max-width: 62.4rem;
  padding: 0rem 2.4rem;
}

.print-ticket-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  word-wrap: break-word;
  grid-template-rows: 50px;
  @media screen and (max-width: 685px) {
    display: block;
  }
}
.print-ticket {
  text-overflow: hidden;
  width: 65%;
  margin: 0 auto;
  @media screen and (max-width: 685px) {
    margin: 0 8px;
  }
}
.print-ticket-container {
  // border: 2px solid black;
  padding: 24px 56px;
}
.print-ticket-content {
  grid-column: 2 / span 4;
}
.print-ticket-barcode {
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (max-width: 1200px) {
    grid-column-start: 1;
    grid-column-end: 4;
    display: none;
  }
}
.barcode-rotate {
  transform: rotate(-90deg);
  position: absolute;
  margin-top: 30px;
  margin-left: 5px;
}

.print-ticket-text-1 {
  grid-column: 2/3;
}
.print-ticket-text-2 {
  grid-column: 3/4;
}

.preview-text {
  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.print-ticket-receipt-conatiner {
  padding: 92px;
  @media screen and (max-width: 710px) {
    padding: 92px 15px;
  }
}

.print-receipt-text {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 580px) {
    flex-direction: column;
  }
}

.print-border {
  border: solid 2px $shade-1;
  padding: 2rem;
  margin-bottom: 78px;
}
