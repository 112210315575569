/* Header */
.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.review-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

/* Rating Section */
.review-rating {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.review-stars {
  display: flex;
  margin-right: 8px;
}

.star-icon {
  color: orange;
  font-size: 16px;
}

.review-title {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

/* Review Details */
.review-date {
  font-size: 12px;
  color: #555;
  margin-bottom: 8px;
}

.review-verified {
  font-size: 12px;
  font-weight: bold;
  color: orange;
  margin-bottom: 8px;
}

.review-text {
  font-size: 14px;
  color: #333;
  // margin: 0;
}
