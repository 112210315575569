.search-speaker-grid {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 144px;
  grid-template-rows: auto;
  column-gap: 24px;
  row-gap: 16px;
  align-items: center;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px;
  }
}
