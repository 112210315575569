@import "../../utility//utility.styles.scss";

// ect is external credit types

.ect-table-wrap {
  table {
    width: 100%;
    border-spacing: 0;
    @include avenir-heavy-18;
    thead {
      tr {
        background-color: $shade-1;
        th {
          padding: 16px;
          text-align: left;
          &:last-child {
            text-align: right;
          }
          @media screen and (max-width: 400px) {
            padding: 12px;
            font-size: 16px;
          }
          @media screen and (max-width: 360px) {
            padding: 10px;
            font-size: 14px;
          }
        }
      }
    }
    tbody {
      td {
        padding: 16px;
        text-align: left;
        color: $gray-2;
        &:last-child {
          text-align: right;
        }

        @media screen and (max-width: 400px) {
          padding: 12px;
          font-size: 16px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        @media screen and (max-width: 360px) {
          padding: 10px;
          font-size: 14px;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
