@use './utility/media.mixins.scss' as media;
@import './utility/utility.styles.scss';
@import './utility/form.styles.scss';
@import './utility/font.styles.scss';
@import './utility/button.styles.scss';
@import './utility/images.styles.scss';
@import './utility/card.styles.scss';
@import './utility/table.styles.scss';
@import './utility/createEvent.styles.scss';

/* Universal set up */
:root {
  --swiper-theme-color: #55a0fa;
  @media screen and (max-width: 680px) {
    --swiper-navigation-size: 24px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Font styles
// setting up root font size to 10px instead default of 16px for calculation of rem for responsive design

html {
  font-size: 10px;
}

a {
  text-decoration: none;
}

// creating font-face to be used

@font-face {
  font-family: Avenir;
  src: local(Avenir), url(./fonts/Avenir.ttc) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Avenir-Heavy;
  src: local(Avenir-Heavy), url(./fonts/Avenir-Heavy.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Avenir-Medium;
  src: local(Avenir-Medium), url(./fonts/Avenir-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Avenir-Roman;
  src: local(Avenir-Roman), url(./fonts/Avenir-Roman.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

// background color classes

.bg-white {
  background-color: $colour-white;
}
.bg-green {
  background-color: $accent;
}

.bg-primary {
  background-color: $primary;
}

.bg-background {
  background-color: $background;
}
.bg-shade-2 {
  background-color: $shade-2;
}

// containers

// container  class is applied to main div of each page
.container {
  width: 100%;
  max-width: $containerWidth;
  // min-height: calc(100vh - 6.4rem);
  min-height: 100vh;
  margin: 0 auto;
  background-color: $background;
}
// to be used directly on a page, not for modal
.container-sm {
  max-width: 100%;
  width: 480px;
  margin: 0 auto;
  padding: 1.6rem;
}

// to be used directly on a page, not for modal
.container-lg {
  max-width: 100%;
  width: 960px;
  margin: 0 auto;
  padding: 1.6rem;
}

// to be used directly on a page, not for modal
.container-xl {
  max-width: 100%;
  width: 1200px;
  margin: 0 auto;
  padding: 1.6rem;
}

//  innter containers are to be used with containers of same size
.inner-container-sm {
  width: 100%;
  height: 100%;
  padding: 5%;
  border: 1px solid $shade-1;
  background-color: $colour-white;
  border-radius: 8px;
}

.dashboard-container {
  display: grid;
  width: 100%;
  height: 100%;
  margin: 6.4rem auto 0;
  padding: 4rem;

  grid-template-columns: 360px 1fr;

  @media screen and (max-width: 1440px) {
    grid-template-columns: 300px 1fr;
  }
  @media screen and (max-width: 960px) {
    display: block;
    padding: 2rem;
  }

  @media screen and (max-width: 600px) {
    padding: 2rem 1.2rem;
  }
}

.sidemenu-container {
  // width comes from dashboard-container grid's first column ie 360px
  width: 100%;
  padding-bottom: 16px;
  min-height: 100vh;
  @media screen and (max-width: 960px) {
    // hide sidemenu container below this
    display: none;
  }
}

.db-mobile-sidemenu {
  position: fixed;
  // width: 0px;
  width: 270px;
  left: 0;
  top: 6.4rem;
  overflow-y: auto;
  z-index: 99;
  background-color: $background;
  // opacity: 0.95;
  height: 100%;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  @media screen and (min-width: 960px) {
    // hide mb-sidemenu above this screen size
    display: none;
  }
}

.container-offSidemenu {
  width: 100%;
  max-width: 100%;
  @media screen and (max-width: 960px) {
    padding-top: 1.2rem;
  }
}

.db-hamberger-container {
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
  }
}

.course-textSm {
  font-size: 1.4rem;
  line-height: 1.25rem;
}

.create-event-res {
  @media screen and (max-width: 960px) {
    display: none;
  }
}

// cd = conference details
.cd-container {
  width: 100%;
  max-width: 114.6rem;
  margin: 0 auto;
  padding: 6.6rem 1.6rem 8.8rem;
}
// bdfo booking details for organizer
.bdfo-container {
  width: 100%;
  max-width: 130rem;
  margin: 0 auto;
  padding: 6.6rem 1.6rem 8.8rem;
}

// grids

.home-conf-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 24px;
}

.grid-col-2 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 8px;

  &-2_1 {
    width: 100%;
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    grid-template-rows: auto;
    gap: 8px;
  }
}

.grid-1st-col {
  grid-column: 1/2;
  // margin: auto 2rem auto 8rem;
}
.grid-2nd-col {
  grid-column: 2/3;
}

.grid-3rd-col {
  grid-column: 3/4;
}

.grid-col-3 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
}

.conf-search-grid {
  margin-top: 3.1rem;
  grid-gap: 4.2rem;
  height: 34.1rem;
}

.grid-col-4 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  @media screen and (max-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-col-5 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
}

.conf-display-grid {
  margin-top: 3.1rem;
  grid-gap: 4.2rem;
  height: 34.1rem;
}

.grid-col-8 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto;
}

// common classes

.color-primary {
  color: $primary;
}

.color-black {
  color: $confemy-black;
}

.conf-display {
  padding-left: 9.7rem;
  padding-right: 9.7rem;
  padding-bottom: 7rem;
  @media screen and (max-width: 320px) {
    padding: 2.2rem;
  }
  @media screen and (max-width: 500px) and (min-width: 350px) {
    padding: 5rem;
  }
}

.position-relative {
  position: relative;
}

.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.margin-0-auto {
  margin: 0 auto;
}

.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: center;
}
.text-align-left {
  text-align: left;
}

.flex {
  display: flex;
  &-wrap {
    flex-wrap: wrap;
  }
}
// flexbox, vertical center

.flex-vc {
  display: flex;
  align-items: center;

  &-wrap {
    flex-wrap: wrap;
  }

  &-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-se {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

.flex-sb {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  // flex-wrap: wrap;
}
// flexbox, horizontal center

.flex-hc {
  display: flex;
  justify-content: center;
}

.pod-flex-vc {
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: block;
  }
}

// flexbox, vertical center, horizontal center

.flex-vchc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

//
.conf-search-input-icon {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left top;
  pointer-events: none;

  svg {
    position: relative;
    padding-top: 5px;
  }
}

.myconfs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  h1 {
    color: $primary;
    padding-right: 1.2rem;
  }
}

.row-menu {
  position: absolute;
  z-index: 10;
  right: 2px;
  top: 0;
  background-color: $shade-2;
  ul {
    border: 1px solid $shade-4;
    list-style: none;
    li {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      padding: 2.4px 8px;
      border-bottom: 1px solid $shade-1;

      &:first-child {
        background-color: $shade-1;
      }

      &:hover {
        background-color: $shade-3;
        font-weight: bold;
        color: $confemy-black;
        cursor: pointer;
      }
    }
  }
}

.gridtable-row-menu {
  position: absolute;
  z-index: 999;
  right: 2px;
  top: 0;
  background-color: $shade-2;
  border: 1px solid $shade-4;
  width: 120px;
  font-size: 12px;

  & > div {
    padding: 2.4px 8px;
    text-align: left !important;
    border-bottom: 1px solid $shade-1;

    &:first-child {
      background-color: $shade-1;
    }
    &:hover {
      // background-color: rgb(236, 240, 242, 0.9);
      font-weight: bold;
      color: $confemy-black;
      cursor: pointer;
    }
  }
}

// dashboard two filter container
.two-filters-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 8px;
  // small screen view
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

// regular icon size
.icon-xxs {
  width: 1.4rem;
  height: 1.4rem;
}
.icon-xs {
  width: 1.6rem;
  height: 1.6rem;
}

.icon-sm {
  width: 2rem;
  height: 2rem;
}

.icon-size {
  width: 2.4rem;
  height: 2.4rem;
}

// large icon size

.icon-lg {
  width: 2.8rem;
  height: 2.8rem;
}

.icon-xl {
  width: 3.2rem;
  height: 3.2rem;
}

.icon-xxl {
  width: 40px;
  height: 40px;
}

.camera-icon {
  // width: 50px;
  // height: 45px;
  width: 80px;
  height: 72px;

  @media screen and (max-width: 600px) {
    width: 56px;
    height: 50.4px;
  }

  @media screen and (max-width: 600px) {
    width: 48px;
    height: 43.2px;
  }
}

.right-input-icon {
  position: absolute;
  right: 2.4rem;
  top: 50%;
  transform: translate(0, -50%);
  transform-origin: left top;
}

// .right-input-icon {
//   position: absolute;
//   // transform: translate(0, -50%);
//   // top: 50%;
//   right: 2.4rem;
//   top: 1rem;
// }

.left-input-icon {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left top;
  pointer-events: none;
}

.right-outside-icon {
  position: absolute;
  right: -3.8rem;
  top: 50%;
  transform: translate(0, -50%);
  transform-origin: left top;
}

.vimeo-icon-size {
  width: 46px;
  height: 40px;
}
.youtube-icon-size {
  width: 57px;
  height: 40px;
}

.facebook-icon-size {
  width: 44px;
  height: 44px;
}

.celebration-icon-size {
  width: 54px;
  height: 54px;
}

.sketch-md {
  max-width: 100%;
  width: 300px;
  height: 204px;
}

// modal classes

.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // max-width: 144rem;
  height: 100%;
  z-index: 100;
  background-color: $modal-background;
}

.modal-body {
  margin: 0 auto;
  position: fixed;
  z-index: 101;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: white;
  // border-radius: 0.8rem;
  // box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
}

.modalx-body {
  display: flex;
  min-width: 28rem;
  max-width: 90%;
  max-height: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 0.8rem;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
}

.modalx-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1.2rem 1.2rem 0rem;
}

.modalx-content {
  width: 100%;
  // height: auto;
  max-height: 85vh;
  overflow-y: auto;
}

// for modalx
// different sizes of modalx inner containers. Decides size of modal x
.modalx-container-sm {
  max-width: 100%;
  width: 480px;
  margin: 0 auto;
  padding-left: 10%;
  padding-right: 10%;
}

.modalx-container-md {
  max-width: 100%;
  margin: 0 auto;
  width: 70rem;
}

.modalx-container-lg {
  max-width: 100%;
  margin: 0 auto;
  width: 90rem;
}

.modal-footer {
  text-align: center;
  border-top: solid 1px $shade-1;
  padding-top: 3rem;
}

.or-container {
  opacity: 0.67;
  display: flex;
  align-items: center;
  margin: 3.2rem auto;
  text-align: center;
}

.or-line {
  margin: auto 2.2rem;
  display: inline-block;
  width: 175px;
  height: 1px;
  background-color: $shade-1;
}
// print margin

.print-margins {
  padding: 1in 0.52in 1in 1in;
}
// Margin & Padding

$spaceamounts: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  44,
  48,
  52,
  56,
  60,
  64,
  68,
  72,
  76,
  80,
  84,
  88,
  92,
  96,
  100
);

@each $space in $spaceamounts {
  // All around margin
  .m-#{$space} {
    margin: #{$space}px;
  }

  // Vertical Margin
  .my-#{$space} {
    margin: #{$space}px 0;
  }

  // horizontal margin

  .mx-#{$space} {
    margin: 0 #{$space}px;
  }

  // margin top
  .mt-#{$space} {
    margin-top: #{$space}px;
  }
  // margin right
  .mr-#{$space} {
    margin-right: #{$space}px;
  }
  // margin left
  .ml-#{$space} {
    margin-left: #{$space}px;
  }
  // margin bottom
  .mb-#{$space} {
    margin-bottom: #{$space}px;
  }

  // All around Padding
  .p-#{$space} {
    padding: #{$space}px;
  }

  // Vertical Padding
  .py-#{$space} {
    padding: #{$space}px 0;
  }

  // Horizontal padding
  .px-#{$space} {
    padding: 0 #{$space}px;
  }
  // padding top
  .pt-#{$space} {
    padding-top: #{$space}px;
  }
  // padding right
  .pr-#{$space} {
    padding-right: #{$space}px;
  }
  // padding left
  .pl-#{$space} {
    padding-left: #{$space}px;
  }
  // padding bottom
  .pb-#{$space} {
    padding-bottom: #{$space}px;
  }
}

// transition classes
.zero-height {
  height: 0px;
}

// drag box

// .drag-box {
//   width: 600px;
//   height: 260px;
//   border-radius: 15px;
//   margin: 32px 0;
//   padding: 100px 150px;
//   background-color: #ecf0f2;
//   text-align: center;
//   font-family: Avenir-Roman;
//   font-size: 18px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.33;
//   letter-spacing: 0.2px;
//   text-align: center;
//   color: lightgray;
// }

// for conference carousal

// ccah is conf carousal and heading
.ccah-container {
  width: 100%;
  margin: 0 auto 1.6rem;
}
// ccah is conf carousal and heading
.ccah-header-wrap {
  width: 100%;
  margin: 0 auto;
  padding: 0 5.6rem;
  max-width: 136rem;
  @media screen and (max-width: 680px) {
    padding: 0 3.2rem;
  }
  @media screen and (max-width: 650px) {
    text-align: center;
  }
  @media screen and (max-width: 350px) {
    padding: 0 2rem;
  }
}

// table cell words break and wrap
.cell-hyphens {
  word-wrap: break-word;
  max-width: 1px; /* its  hack */
  -webkit-hyphens: auto; /* iOS 4.2+ */
  -moz-hyphens: auto; /* Firefox 5+ */
  -ms-hyphens: auto; /* IE 10+ */
  hyphens: auto;
}

// for ellipsis give a size to parent element
.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Nothing matches search result
.sr-noresult-container {
  // padding-top: 8rem;
  text-align: center;

  svg {
    width: 198px;
    height: 298px;
  }
  h2 {
    color: #08415c;
  }
}

// social media share popup
.right-share-card {
  width: 232px;
  height: 64px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 2px 2px rgba(#000, 0.09);
  background-color: #fff;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.share-btn {
  margin: 0 8px;
}

.left-share-card {
  width: 232px;
  height: 64px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 2px 2px rgba(#000, 0.09);
  background-color: $background;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.hover-bg {
  &:hover {
    background-color: $shade-1;
    border-radius: 2px;
  }
}

// ab is attendee booking
.ab-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  border-radius: 4px;
  background-color: $colour-white;
}

.conf-add-newcredit-btn {
  &:hover {
    color: #073b54;
    cursor: pointer;
  }
  &:active {
    color: #063449;
    opacity: 0.7;
  }
}

// change all react date pickers close icons class
.react-datepicker__close-icon {
  right: 16px;
  &::after {
    background-color: $colour-white;
    color: #cccccc;
    font-size: 2.4rem;
  }
}

.uc-uploadfile-input {
  border-radius: 4px;
  width: 100%;
  height: 65px;
  padding: 2rem 2.4rem;
  border: solid $shade-1 2px;
  &:focus {
    border: solid $info 2px;
  }
}

.home-conf-grid {
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 28rem);
  grid-template-rows: auto;
  gap: 2.4rem;
  @media screen and (max-width: 1260px) {
    grid-template-columns: repeat(3, 28rem);
  }
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 28rem);
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 27rem);
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.basicInfo-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sns-buttons-wrap {
  button {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
// give bg color with style tag to get diffrent colors
.round-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(93, 95, 239, 0.29);
}

.media-player-preview .modalx-content {
  width: 94%;
  height: auto;
  max-height: 100vh;
  margin: 0 auto;
}

.react-player-container {
  position: relative;
}
.react-player-video-logo {
  position: absolute;
  top: 10px; /* Position your logo as desired */
  right: 10px;
  width: 50px; /* Adjust size of logo */
  opacity: 0.8; /* Optional: add transparency */
}
