@import '../../utility//utility.styles.scss';

.myconfs-sort {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 8px;
  // small screen view
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.myconfs-table {
  width: 100%;
  margin-bottom: 24px;
  border-collapse: collapse;
  @include avenir-heavy-16;
  color: $gray-3;
  font-size: 16px;
  border: 1px solid #ced9de;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  overflow: visible;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }

  thead {
    tr {
      background-color: $shade-1;
      border: 1px solid $shade-1;
      th {
        padding: 16px;
        text-align: left;
        &:last-child {
          text-align: right;
        }
        &:first-child {
          padding-right: 20px;
        }
        @media screen and (max-width: 1200px) {
          padding: 16px 10px;
        }
        @media screen and (max-width: 600px) {
          padding: 16px 8px;
        }
      }
    }
  }
  tbody {
    vertical-align: top;
    td {
      padding: 16px;
      text-align: left;
      color: $gray-2;

      &:first-child {
        padding-right: 20px;
      }
      @media screen and (max-width: 1200px) {
        padding: 10px;
      }
      @media screen and (max-width: 600px) {
        padding: 8px;
      }
    }

    tr {
      // background-color: $shade-2;
      // border: 1px solid $background;

      // every odd row is backcolor white
      // &:nth-child(odd) {
      //   background-color: #fff;
      // }

      // first td of every row as there are 4 tds in each row
      // & > td:nth-child(4n + 1) {
      //   color: $confemy-black;
      // }

      // 5th td of every row as there are 5 tds in each row
      & > td:nth-child(5n + 5) {
        text-align: right;
      }
      // &:hover {
      //   background-color: $shade-2;
      //   cursor: grab;
      // }
      // &:active {
      //   background-color: $background;
      // }
    }
  }
}

#myConfsSearchText {
  padding-left: 5.2rem;
}

.myconfs-actions {
  background-color: #fff;
  text-align: left;
  ul {
    list-style: none;
    border: 2px solid red;

    li {
      width: 296px;
      padding: 1rem 2rem;
      @include body-regular-gray3;
      &:hover {
        background-color: $shade-2;
      }
    }
  }
}
