@import "../../utility//utility.styles.scss";

.key-points-box {
  width: 100%;
  height: auto;
  padding: 1.2rem;
  border: 2px solid $shade-1;
}

.key-points-bdy {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.key-points-ul {
  list-style-position: inside;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.2rem;
  row-gap: 0.4rem;
}

.key-points-li {
  font-size: 1.6rem;
  list-style: none;
  &::before {
    content: attr(data-content);
    margin-right: 0.4rem;
  }
}
