@import "../../utility/utility.styles.scss";

.sidemenu-inner {
  width: 270px;
  height: auto;
  padding: 0px 16px;
  flex-shrink: 0;
  color: black;
  h2 {
    font-family: Avenir;
    &:hover {
      cursor: pointer;
    }
  }
  ul {
    list-style: none;
  }
}

.sidenav-item {
  display: flex;
  align-items: center;
  padding: 16px;
  @include caption-1-regular;
  color: $gray-3;
  transition: background-color 1s, border-radius 1.5s, color 2s;
  border-radius: 5px;

  &:hover {
    background-color: $shade-2;
    font-weight: bold;
    border-radius: 5px;
    color: $confemy-black;
    cursor: pointer;
  }
  svg {
    margin-left: auto;
  }
}
