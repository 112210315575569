/* Confemy Colors */

$primary: #08415c;
$shade-2: #ecf0f2;
$shade-3: #fcfdfd;
$shade-1: #ced9de;
$shade-4: #aabdc7;
$accent: #4cb944;
$colour-white: #ffffff;
$confemy-black: #000000;
$gray-1: #c4c4c4;
$gray-2: #757575;
$gray-3: #444444;
$gray-4: #6a6f73;
$warning: #d8000c;
$background: #fafbfc;
$marine-34: rgba(8, 65, 92, 0.35);
$info: #55a0fa;
$lime-green: #38b52f;
$dark-purple: #8710d8;

$modal-background: rgba(0, 0, 0, 0.4);

// main website container width
// $containerWidth: 1440px;
$containerWidth: 100%;

// dashboar side menu width
$organizersNavbarWidth: 360px;

// margins container-offSidemenu
$mlContainerOffSidemenu: 120px;
$mrContainerOffSidemenu: 20px;

$confcard-width: 28rem;
// $confcard-height: 34.1rem;
// height before is 35.5rem by khanderao
$confcard-height: 37.5rem;
$hovcard-width: 29.4rem;
$hovcard-height: 48.75rem;

@mixin body-regular {
  font-family: Avenir-Roman;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.2px;
}

@mixin body-regular-gray3 {
  @include body-regular;
  color: $gray-3;
}

@mixin body-regular-a {
  font-family: Avenir;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.2px;
}

@mixin body-regular-a-gray3 {
  @include body-regular-a;
  color: $gray-3;
}

@mixin Section-title-1 {
  font-family: Avenir-Heavy;
  font-size: 30px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.01px;
  color: $primary;
}

@mixin caption-1-regular {
  font-family: Avenir-Roman;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin caption-1-medium {
  font-family: Avenir-Medium;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin caption-2-regular {
  font-family: Avenir-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin caption-2-bold {
  font-family: Avenir-Heavy;
  font-size: 14px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin caption-1-heavy {
  font-family: Avenir-Heavy;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin avenir-roman-18 {
  font-family: Avenir-Roman;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin avenir-roman-16 {
  font-family: Avenir-Roman;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin avenir-heavy-18 {
  font-family: Avenir-Heavy;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
}

@mixin avenir-heavy-16 {
  font-family: Avenir-Heavy;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
}

@mixin arial-16 {
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin avenir-heavy-20 {
  font-family: Avenir-Heavy;
  font-size: 2rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.22px;
  color: $confemy-black;
}

@mixin avenir-heavy-24 {
  font-family: Avenir-Heavy;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
}

@mixin avenir-500 {
  font-family: Avenir;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #08415c;
}
