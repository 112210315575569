@import "../../utility/utility.styles.scss";

// create conference form
.required-label {
  font-size: 18px;
  &::after {
    content: " *";
    color: #ff0000;
    font-weight: bolder;
    font-size: 16px;
  }
}

.conf-form-wrap {
  // margin-right: 24px;
  h2 {
    color: $primary;
    margin-bottom: 32px;
  }

  h4 {
    font-family: Avenir;
    font-weight: normal;
    color: $primary;
    margin-bottom: 16px;
  }
}

.items-makeline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.items-makeline-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.big-checkbox {
  width: 1rem;
  height: 1rem;
}

.border-medium-grey {
  border: 1px solid #d8d8d8;
}

// ConfBasicInfo classes

.conf-schedule {
  grid {
    grid-gap: 8px;
  }
}
// display styles

.display-enter {
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  height: 0px;
  color: transparent;
  opacity: 0;
  background-color: $background;
}

.display-enter-active {
  border-radius: 4px;
  padding: 20px;
  opacity: 1;
  width: 100%;
  height: 140px;
  color: black;
  background-color: $shade-2;
  transition: all 300ms;
}

.display-enter-done {
  border-radius: 4px;
  padding: 20px;
  opacity: 1;
  width: 100%;
  height: 140px;
  color: black;
  background-color: $shade-2;
}
.display-exit {
  border-radius: 4px;
  padding: 20px;
  opacity: 1;
  width: 100%;
  height: 140px;
  color: black;
  background-color: $shade-2;
}

.display-exit-active {
  border-radius: 4px;
  padding: 20px;
  opacity: 0;
  width: 100%;
  height: 0px;
  color: transparent;
  background-color: $background;
  transition: all 300ms;
}

.display-exit-done {
  border-radius: 4px;
  padding: 20px;
  opacity: 0;
  width: 100%;
  height: 0px;
  color: transparent;
  background-color: $background;
}

// styles for details page 1

.tags-modal-contentwrap {
  width: 400px;
  h2 {
    text-align: center;
    color: $primary;
  }
}

.tags-display {
  display: flex;
  flex-wrap: wrap;

  li {
    // height: 50px;
    display: flex;
    align-items: center;
    list-style: none;
    font-family: Avenir-Heavy;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.01px;
    color: $gray-3;
    // padding: 12px;
    padding: 5px;
    margin: 4px;
    // margin-bottom: 12px;
    border-radius: 4px;
    // background-color: $shade-2;
    background-color: hsl(0, 0%, 90%);

    svg {
      margin-left: 8px;
      width: 14px;
      height: 14px;
    }
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: start;
  }
}

// add credits modal its content
.addcredit-grid {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 132px;
  grid-template-rows: auto;
  column-gap: 24px;
  row-gap: 8px;
  align-items: center;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px;
  }
}

.conf-credits-table {
  width: 100%;
  border-spacing: 0;
  @include avenir-heavy-18;
  thead {
    tr {
      background-color: $shade-1;
      th {
        padding: 16px;
        text-align: left;
        &:last-child {
          text-align: center;
        }
        @media screen and (max-width: 350px) {
          padding: 12px;
          font-size: 16px;
        }
        // 1st th
        & > th:nth-child(3n + 1) {
          width: 50%;
        }
      }
    }
  }
  tbody {
    td {
      padding: 16px;
      text-align: left;
      color: $gray-2;
      @media screen and (max-width: 350px) {
        padding: 10px;
        font-size: 16px;
      }
    }

    tr {
      background-color: $shade-2;
      // background-color: $colour-white;

      // every odd row is backcolor white
      &:nth-child(odd) {
        background-color: #fff;
      }

      // first td of every row as there are 4 tds in each row
      & > td:nth-child(3n + 1) {
        width: 50%;
        color: $confemy-black;
      }

      // 4th td of every row as there are 4 tds in each row
      & > td:nth-child(3n + 3) {
        text-align: center;
      }
    }
  }
}

.details2-venue-wrap {
  margin-bottom: 200px;
}

.details2-venue {
  padding: 24px;
  background-color: $colour-white;
  margin-bottom: 32px;
}

.details2-image-dropzone {
  width: 100%;
  height: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin: 24px 222px 24px 79.5px;
  // padding: 139px 331px 141px 334px;
  border-radius: 8px;
  background-color: $shade-2;
  &:hover {
    cursor: pointer;
  }
}

.details2-camera-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 11px 11px 11px 9px;
  border-radius: 50%;
  background-color: $colour-white;
}

.details2-camera-icon {
  width: 32px;
  height: 28px;
  // width: 24px;
  // height: 21px;
}

.add-speaker-button {
  width: 100%;
  // width: 392px;
  height: 290px;
  // height: 304px;
  max-width: 322px;
  // max-width: 392px;
  max-height: 290px;
  // max-height: 304px;

  border: 1px solid $shade-1;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ecf0f2;
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colour-white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.conf-speaker-grid {
  width: 100%;
  // margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 322px);
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 16px;
  align-items: center;
  justify-content: left;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 322px);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 322px;
  }

  @media screen and (max-width: 360px) {
    grid-template-columns: 1fr;
  }
}

// conference banner
// The banner aspect ratio is 16:9 ie 1.7777
// 1920 x 1080 pixels, its standard HD ratio for HD TVs, presentations, and social media cover photos.
// 1280 x 720 pixels, its standard HD format in photography and film.

.confbanner-container {
  position: relative;
  max-width: 817.77px;
  min-width: 244px;
  width: auto;
  height: auto;
  aspect-ratio: 16/9;
  // width: 817.77px;
  // height: 460px;
  background-color: $colour-white;
  &:hover {
    & > .confbanner-overlay {
      opacity: 0.4;
      transition: all 300ms ease;
    }

    & > .confbanner-wrap {
      padding: 8px;
      transition: padding 300ms ease;
      background-color: $shade-2;
    }

    & > .confbanner-delete-circle {
      opacity: 1;
      transition: opacity 300ms ease;
    }
  }
}

.confbanner-wrap {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  padding: 0px;
  transition: padding 400ms ease;
}

.confbanner-delete-circle {
  width: 54px;
  height: 54px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colour-white;
  border-radius: 50%;

  position: absolute;
  transition: 300ms ease;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.confbanner-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 300ms ease;
  border-radius: 4px;
  background-color: $confemy-black;
}

.confbanner {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 300ms ease;
  backface-visibility: hidden;
}
// images are in aspect ratio of 16:9
.confbanner-dropzone-container {
  max-width: 817.77px;
  min-width: 244px;
  width: auto;
  height: auto;
  aspect-ratio: 16/9;
  // max-width: 917.77px;
  // height: 460px;
}

.banner-swiper {
  margin: 0;
  width: 100%;
  max-width: 817.77px;
  height: 214.58px;
  padding: 40px;
  border: 2px solid $shade-1;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide img {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-slide {
    border: 1px solid red;
    background-size: cover;
    background-position: center;
  }
}

// live stream designs

.livestream-container {
  // margin-right: 24px;
  h2 {
    color: $primary;
  }
}

.livestream-tabs-container {
  display: grid;
  align-items: center;
  justify-content: space-between;
}

@mixin livestream-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  @media screen and (max-width: 650px) {
    width: 60px;
    height: 60px;
  }
}

.livestream-tab {
  @include livestream-tab;
  background-color: $shade-2;

  &:hover {
    background-color: $shade-1;
  }
}

.active-livestream-tab {
  @include livestream-tab;
  background-color: $shade-1;
}

.livestream-tab-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 650px) {
    margin-bottom: 10px;
  }
}

.livestram-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1;
  gap: 32px;

  @media screen and (max-width: 450px) {
    grid-template-columns: 5fr 1fr;
  }
}

//  conference tickets styles

.conf-notickets-container {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

.planeticket-icon-size {
  // width: 239px;
  // height: 160px;
  width: 191px;
  height: 128px;
}

.conftickets-table-wrap {
  table {
    width: 100%;
    border-spacing: 0;
    @include avenir-heavy-18;
    thead {
      tr {
        background-color: $shade-1;
        th {
          padding: 16px;
          text-align: left;
          &:last-child {
            text-align: center;
          }
          @media screen and (max-width: 400px) {
            padding: 12px;
            font-size: 17px;
          }
          @media screen and (max-width: 360px) {
            padding: 10px;
            font-size: 16px;
          }
        }
        // second th
        & > th:nth-child(4n + 1) {
          width: 50%;
        }
        // second th
        & > th:nth-child(4n + 2) {
          width: 20%;
        }
        // second th
        & > th:nth-child(4n + 3) {
          width: 20%;
        }
        // second td
        & > th:nth-child(4n + 4) {
          width: 10%;
        }
      }
    }
    tbody {
      td {
        padding: 16px;
        text-align: left;
        color: $gray-2;
        @media screen and (max-width: 400px) {
          padding: 12px;
          font-size: 17px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        @media screen and (max-width: 360px) {
          padding: 10px;
          font-size: 12px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      tr {
        background-color: $shade-2;

        // every odd row is backcolor white
        &:nth-child(odd) {
          background-color: #fff;
        }

        // first td of every row as there are 4 tds in each row
        & > td:nth-child(4n + 1) {
          width: 50%;
          color: $confemy-black;
        }
        // second td
        & > td:nth-child(4n + 2) {
          width: 20%;
        }
        // 3rd td
        & > td:nth-child(4n + 3) {
          width: 20%;
        }
        // 4th td of every row as there are 4 tds in each row
        & > td:nth-child(4n + 4) {
          width: 10%;
          text-align: center;
          i {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.inactive-isitfree {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 16px;
  border-radius: 4px;
  color: $gray-3;
  background-color: $shade-1;
  margin-bottom: 2.4rem;
  @include caption-1-regular;
}

.ticket-form-container {
  max-width: 100%;
  margin: 0 auto;
  width: 52rem;

  // width: 520px;
  // padding: 4rem 0 0;
  // @media screen and (max-width: 550px) {
  //   width: 80vw;
  // }
}

// Preview page

.preview-imgcard-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 40.8rem;
  }
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
  @media screen and (max-width: 800px) {
    max-width: 40.8rem;
    grid-template-columns: 1fr;
  }
}
.preview-img-wrap {
  // width: 100%;
  max-height: 261px;
  aspect-ratio: 16/9;
  background-color: $colour-white;
  overflow: hidden;
}

.preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-noimg-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: #ecf0f2;
}

.preview-card {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  // width: 100%;
  // width: 408px;
  // height: 261px;
  padding: 16px 16px 12px;
  background-color: $colour-white;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  button {
    width: 100%;
    padding: 12px;
  }
}

.preview-label-wrap {
  label {
    display: inline-block;
  }
}

.publish-date-wrap {
  max-width: 300px;
}

.live-stream {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  // @media screen and (max-width:650px){
  //   grid-template-columns: repeat(4,1fr);
  //   gap:1rem
  // }
}

// conference preview

.conf-preview-wrap {
  width: 144rem;
  max-width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 480px) {
  .live-stream {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

@media screen and (max-width: 408px) {
  .basicInfo-btn {
    width: 70%;
  }
}

.create-conf-table-wrap {
  width: 100%;
  max-width: 600px;
  table {
    width: 100%;
    border-collapse: collapse;
    @include avenir-heavy-18;
    color: $primary;
    font-size: 16px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    tbody {
      td {
        padding: 8px 8px;
        text-align: left;
        color: $gray-2;
        @media screen and (max-width: 600px) {
          padding: 8px 4px;
        }
        &:first-child {
          padding-right: 16px;
          @media screen and (max-width: 600px) {
            padding-right: 12px;
          }
        }
        &:nth-child(4n + 3) {
          @media screen and (max-width: 600px) {
            padding-right: 0px;
            button {
              font-size: 14px;
            }
          }
        }
        &:last-child {
          @media screen and (max-width: 600px) {
            padding-right: 0px;
            button {
              font-size: 14px;
            }
          }
        }
      }

      tr {
        &:hover {
          background-color: $colour-white;
          cursor: grab;
        }
        &:active {
          background-color: $background;
        }
      }
    }
  }
}
