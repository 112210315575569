@import "../../utility//utility.styles.scss";

// mic is media includes card

.mic-box {
  width: 21rem;
  height: 12rem;
  padding: 0.8rem;
  border: 2px solid $shade-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
