.courseOverview-outerDiv {
  width: 100%;
  height: 100%;
  padding: 0 1.6rem;
  color: #1c1d1f;
  background-color: #fff;
}

.courseOverview-tabs {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #d1d7dc;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.courseOverview-tab {
  padding: 1rem;
  text-decoration: none;
  font-weight: 700;
  color: #6a6f73;
}

.courseOverview-activeTab {
  color: #08415c;
  border-bottom: 0.3rem solid #08415c;
}

.courseOverview-icon {
  width: 15px;
  height: 15px;
}

.courseOverview-content {
  padding: 1rem;
  width: 100%;
  height: 100%;
}

.course-overview-textSm {
  padding: 0.4rem 0.4rem;
  font-size: 16px;
  font-weight: 600;
}
