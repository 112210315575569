@import "../../utility/utility.styles.scss";

// bdfo = booking details for organizer

.bdfo-counts-wrap {
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;

  table {
    width: 100%;
    margin-bottom: 16px;
    background-color: #fff;
    border-collapse: collapse;
    @include avenir-roman-18;
    color: $gray-3;
    border: 1px solid $shade-1;
    @media screen and (max-width: 700px) {
      font-size: 16px;
    }
    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
    @media screen and (max-width: 350px) {
      font-size: 12px;
    }

    thead {
      vertical-align: top;
      tr {
        th {
          padding: 12px 16px 0px;
          text-align: center;
          border-right: 1px solid $shade-1;
          @media screen and (max-width: 700px) {
            padding: 12px 12px 0px;
          }
          @media screen and (max-width: 650px) {
            padding: 8px 8px 0px;
          }
          @media screen and (max-width: 500px) {
            padding: 8px 8px 0px;
          }
        }
      }
    }

    tbody {
      vertical-align: top;
      td {
        @include avenir-heavy-20;
        padding: 12px 16px 24px;
        text-align: center;
        border-right: 1px solid $shade-1;
        @media screen and (max-width: 700px) {
          padding: 12px 12px 24px;
          font-size: 18px;
        }
        @media screen and (max-width: 650px) {
          padding: 8px 8px 16px;
          font-size: 16px;
        }
        @media screen and (max-width: 500px) {
          padding: 8px 8px 8px;
          font-size: 14px;
        }
        @media screen and (max-width: 350px) {
          font-size: 12px;
        }
      }
    }
  }
}
