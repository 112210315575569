@import '../../utility//utility.styles.scss';
.auth-dropdown {
  width: 32rem;
  height: 33.4rem;
  max-height: calc(100vh - 6.4rem);
  max-width: 100%;
  position: absolute;
  z-index: 1;
  overflow: auto;
  top: 6.4rem;
  right: 0;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.08);
  background-color: $colour-white;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    li {
      position: relative;
      flex-grow: 1;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 6.6rem;

      a {
        @include body-regular-a-gray3;
      }

      svg {
        position: relative;
        top: 0.2rem;
        margin-left: 2.4rem;
        margin-right: 2.4rem;
      }

      &:hover {
        background-color: $shade-2;
      }
    }
  }
}
