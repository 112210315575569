@import './utility.styles.scss';

// create e is create event
// creat-event-wrap is 659px
// inside that place create event grid which has 1fr 6.4rem columns
// so first column has 595px same as create-e-inner-wrap and second column has 64px.

.create-event-wrap {
  width: 100%;
  max-width: 659px;
}

// create-event-wrap width - ce-hanging-buttons-wrap's width - create-event-grid's column-gap
.create-e-inner-wrap {
  width: 100%;
  max-width: 579px;
}

.create-event-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6.4rem;
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 8px;
  @media screen and (max-width: 600px) {
    // hide mb-sidemenu above this screen size
    display: block;
  }
}

.ce-hanging-buttons-wrap {
  width: 6.4rem;
  height: 6.6rem;
  padding: 0 8px;
  position: absolute;
  left: 100%;
  top: 0;
  display: flex;
  align-items: center;
}

.form-type-4-box {
  width: 100%;
  display: block;
  padding: 2rem;
  font-size: 1.6rem;
  border-radius: 4px;
  // border: solid 2px $shade-1;
  border: solid 2px $primary;
  background-color: $colour-white;
  @include avenir-500;
  transition: 0.1s ease-out;
  // &:hover {
  //   border: solid 2px $shade-4;
  // }
  // &:focus {
  //   border: 2px solid $info;
  // }
}

.dark-placeholder::placeholder {
  color: #333333 !important;
}

.event-banner-dropzone-container {
  max-width: 400px;
  min-width: 244px;
  width: auto;
  height: auto;
  aspect-ratio: 16/9;
}

.ce-points-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.full-width-griditem {
  grid-column: 1/-1;
}

.ce-insert-button {
  // width: 240px;
  width: 100%;
  height: 66px;
  padding: 2rem;
  border-radius: 4px;
  border: solid 2px #ced9de;
  background-color: #fff;
  @include avenir-500;
  font-size: 1.6rem;
  cursor: pointer;
}

//

.add-section-button {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 6.6rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  border: solid 2px $accent;
  background-color: #fff;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1.8rem;
  // font-size: 2rem;
  @include avenir-500;
  color: $lime-green !important;
  &:hover {
    border-color: rgba(76, 185, 68, 0.6);
    color: rgba($color: $lime-green, $alpha: 0.6);
  }
  &:active {
    border-color: $accent;
    color: $lime-green;
  }
}

.add-chapter-button {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 6.6rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  border: solid 2px $shade-1;
  background-color: #fff;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1.8rem;
  // font-size: 2rem;
  @include avenir-500;
  // font-size: 1.6rem !important;
  color: $shade-1 !important;
  &:hover {
    border-color: rgba($color: $shade-1, $alpha: 0.8);
    color: rgba($color: $shade-1, $alpha: 0.8);
  }
  &:active {
    border-color: $shade-1;
    color: $shade-1;
  }
}

.L-indent {
  width: 14px;
  height: 12.5px;
  // margin: 20.5px 12px 33px 0;
  border-left: solid 2.5px #ced9de;
  border-bottom: solid 2.5px #ced9de;
}

.ce-section-grid {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 6.4rem;
  grid-template-rows: auto;
  column-gap: 12px;
  row-gap: 8px;
}

.ce-episode-list {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr 6.4rem;
  grid-template-rows: auto;
  align-items: center;
  column-gap: 12px;
  row-gap: 8px;
}

.media-dropzone {
  width: 300px;
  height: 169px;
  // padding: 59px 125px 58px 124px;
  border-radius: 4px;
  border: solid 2px $shade-1;
  background-color: #fff;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.media-preview-wrap {
  width: 300px;
  max-width: 100%;
  height: auto;
  // height: 169px;
  padding: 8px;
  border-radius: 4px;
  border: solid 2px #ced9de;
  background-color: #fff;
}

.change-media-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93px;
  height: 25px;
  // padding: 2px 10px 4px;
  border-radius: 4px;
  background-color: rgba(8, 65, 92, 0.57);
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.ce-price-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: auto;
  column-gap: 0;
  // row-gap: 8px;
}

.ce-preview-gradient {
  width: 100%;
  height: 148px;
  border-radius: 6px;
  background-image: linear-gradient(to right, #1189c2 -37%, #08415c 59%);
}

.ce-preview-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

.ce-preview-col1 {
  width: 100%;
  margin: 0 auto;
  padding: 30px 0px;
}

.ce-preview-col2 {
  width: 100%;
  margin: 0 auto;
}

// cep is create event preview

.cep-sections-wrap {
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
}

.cep-section-box {
  width: 100%;
  height: 40px;
  padding: 8px 8px 8px 16px;
  border-radius: 4px;
  border: solid 2px #ced9de;
  background-color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ce-banner-wrap {
  width: auto;
  height: auto;
  aspect-ratio: 16/9;
  max-width: 278px;
  min-width: 244px;
  border-radius: 8px;
  overflow: hidden;
  // padding: 0px;
  // transition: padding 400ms ease;
}

.ce-banner-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  // opacity: 1;
  // transition: opacity 300ms ease;
  backface-visibility: hidden;
}
