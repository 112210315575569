@import './utility.styles.scss';

.conf-card {
  // width: 28rem;
  // height: 34.1rem;
  margin: 0 auto;
  max-width: $confcard-width;
  height: $confcard-height;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: $colour-white;
  display: flex;
  flex-direction: column;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      position: relative;
      .cc-hovcard {
        width: $hovcard-width;
        max-width: $hovcard-width;
        height: $hovcard-height;
        margin: 0 auto;
        z-index: 1;
        position: absolute;
        top: -15%;
        left: 100%;
        border-radius: 0.4rem;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
        background-color: $colour-white;
        display: block;
        // @media screen and (max-width: 660px) {
        //   top: 0%;
        //   left: 0%;
        // }
      }
    }
  }
}

.cc-img-container {
  // width: 28rem;
  // height: 15.75rem;
  width: 100%;
  max-width: 100%;
  height: calc(#{$confcard-width * 9 / 16});

  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cc-content-container {
  width: 100%;
  max-width: 100%;
  height: auto;
  // height: 18rem;
  overflow: hidden;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cc-headtrunc-wrap {
  flex-grow: 1;
  &:hover {
    cursor: pointer;
  }
}

// conf card classes useful to other cards
// apply to div containing conf card header

.confcard-header {
  width: 100%;
  height: 5rem;
  max-height: 5rem;
  overflow: hidden;
  font-family: Avenir-Heavy;
  font-size: 2rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.22px;
  color: $confemy-black;
}

.confcard-trunc {
  // padding-top: 16px;
}
.cc-truncitem-wrap {
  max-width: 80%;
  max-height: 2.8rem;
  line-height: 1.4rem;
  overflow: hidden;
}

.cc-likeicon-wrap {
  &:hover {
    cursor: pointer;
  }
}

.conf-card-icons {
  width: 1.4rem;
  height: 1.4rem;
}

.conf-card-text {
  display: inline-block;
}

.conf-card-location {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.conf-price-box {
  margin-top: 2.5rem;
  margin-left: 1.2rem;
}

.conf-likeicon {
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  // top: 0.6rem;
  left: 4rem;
}

.card-grid-container {
  display: grid;
  grid-template-columns: 70% 30%;
  position: relative;
  bottom: 0rem;
}

.confcard-footer {
  width: 100%;
  max-width: 100%;
  height: 3.4rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 2.4rem;
  align-items: center;
  column-gap: 8px;
}

// hover card

.cc-hovcard {
  display: none;
}

.cc-hovcard-inner {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 1.6rem 0rem;
  display: flex;
  flex-direction: column;
}

.cc-hovcard-content {
  flex-grow: 1;
  overflow: auto;
  padding: 0 1.6rem;
  cursor: grab;
}

.cc-hovcard-credits {
  display: grid;
  width: 100%;
  padding: 0px 8px 0px 22px;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  gap: 8px;
}

.coursecard-header {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  // font
  font-family: Avenir-Heavy;
  font-size: 2rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.22px;
  color: $confemy-black;
}

.card-description {
  font-size: 14px;
  color: $gray-4;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// cep create event preview
.ce-preview-card {
  margin: 0 auto;
  width: 300px;
  position: relative;
  top: -60px;
  z-index: 1;
}

.cep-card-topbox {
  width: 100%;
  min-height: 375px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }
}

.cep-card-bottombox {
  padding: 40px 12px 12px 12px;
}

.course-details-card {
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 1;
  width: 300px;
  min-height: 375px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  // display: flex;
  // flex-direction: column;
}

.play-button-circle-wrap {
  // border: 1px solid black;
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.play-button-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-preview-text {
  background-color: #dceef8;
  padding: 0.1rem 0.4rem;
  margin: 1rem 0;
  font-size: 1.6rem;
  letter-spacing: -0.02rem;
  font-weight: 700;
  color: $gray-3;
}

.primary-color-box {
  width: 54px;
  height: 54px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $shade-1;
}

// booking page card

.bpc-container {
  flex-grow: 1;
}

.bpc-card {
  width: 60rem;
  height: auto;
  max-width: 100%;
  display: grid;
  grid-template-columns: 28rem 1fr;
  gap: 2.4rem;

  @media screen and (max-width: 650px) {
    display: block;
    width: 100%;
  }
}

.bpc-img-container {
  width: 28rem;
  height: 15.75rem;
  // width: 100%;
  // max-width: 100%;
  // height: calc(#{$confcard-width * 9 / 16});

  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
