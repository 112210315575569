// as is account settings

.as-container {
  width: 86rem;
  max-width: 100%;
  margin: 0 auto;
  overflow-wrap: break-word;
}

.as-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 8px;
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
}

.as-section-gap {
  margin-bottom: 60px;
  @media screen and (max-width: 732px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 32px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 24px;
  }
}
