@import "../../utility/utility.styles.scss";

.hero-section {
  padding-right: 40px;
  padding-left: 147px;
  padding-top: 32px;
  @media screen and (max-width: 1400px) {
    padding-left: 120px;
  }
  @media screen and (max-width: 1200px) {
    padding-left: 96px;
  }
  @media screen and (max-width: 1000px) {
    padding-left: 80px;
  }
  @media screen and (max-width: 800px) {
    padding-left: 60px;
  }
  @media screen and (max-width: 700px) {
    padding-left: 40px;
  }

  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.hero-container {
  width: 100%;
  max-width: 100%;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 40px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}
.hero-text {
  margin: auto;
  @media screen and (max-width: 700px) {
    margin: auto;
    text-align: center;
  }
}

.hero-btn {
  max-width: 100%;
}

.hero-sketch {
  max-width: 100%;
  // width: 760px;
  // height: 626px;
}

.landing-huge-title {
  font-family: Arial;
  // font-size: 4vw;
  font-size: 3.2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: $primary;
  margin: 0.7rem 1rem 3.2rem 0;
  overflow-wrap: unset;
  @media screen and (max-width: 700px) {
    font-size: 6vw;
  }
}

.landing-page-title {
  font-family: Arial;
  font-size: 2.5vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: $primary;
  @media screen and (max-width: 700px) {
    font-size: 3.5vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 4vw;
  }
}
