@import "../../utility/utility.styles.scss";

@mixin back-button-text {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  // line-height: 1;
  letter-spacing: 0.41px;
  text-align: center;
  color: $colour-white;
}

.back-button {
  display: inline-block;
  outline: none;
  border: 1px solid black;
  cursor: pointer;
  transition: all 0.12s ease-in;
  width: 10rem;
  // height: 2.4rem;
  height: 5.2rem;
  //   padding: 0.2rem 0.2rem;
  border-radius: 4px;
  @include back-button-text;

  &.back-button-green {
    background: $accent;
    border-color: $accent;
    &:hover {
      background-color: #45a93e;
      border-color: #45a93e;
    }
    &:active {
      background-color: #3c9436;
      border-color: #3c9436;
    }
  }
  &.back-button-secondary {
    background: $shade-4;
    border-color: $shade-4;
    &:hover {
      background: $shade-2;
      border-color: $shade-2;
    }
  }

  &.back-button-primary {
    background: $primary;
    border-color: $primary;
    &:hover {
      background: #073b54;
      border-color: #073b54;
    }
    &:active {
      background-color: #063449;
      border-color: #063449;
    }
  }

  &.back-button-red {
    background: $warning;
    border-color: $warning;
    &:hover {
      background: $warning;
      border-color: $warning;
    }
    &:active {
      background-color: $warning;
      border-color: $warning;
    }
  }
}
