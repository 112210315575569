@import '../../utility/utility.styles.scss';

.how-it-works {
  width: 100%;
  margin: 6.8rem auto;
  text-align: center;
  @media screen and (max-width:625px) {
    padding: 15px;
  }
}

.how-it-works-heading {
  margin: 0 119px 17px;
  @media screen and (max-width:500px) {
    font-size: 25px;
    margin: 0 auto 1.7rem;
        
  }
}


// .how-it-works-heading {
//   margin: 0 auto 17px;
// }

// @media screen and (min-width:425px) {
//   .how-it-works-heading {
//   margin: 0 auto 17px;
// }
// }
