// mi is media includes

.mi-body {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.2rem;
  row-gap: 0.8rem;

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
}

.include-title-centre {
  @media screen and (max-width: 800px) {
    text-align: center;
  }
}
