@import "../../utility/utility.styles.scss";

.org-logo-wrap {
  height: 18rem;
  width: auto;
  max-width: 100%;
  overflow: hidden;
}

.org-logo-innerwrap {
  position: relative;
  display: inline-block;
  height: 100%;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  overflow: hidden;
  border: 1px solid $shade-1;
  padding: 4px;

  & > img {
    height: 100%;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }

  &:hover {
    & > .org-logo-overlay {
      opacity: 0.4;
      transition: all 300ms ease;
    }

    & > .org-logo-edit-circle {
      opacity: 1;
      transition: opacity 300ms ease;
    }
  }
}

.org-logo-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 300ms ease;
  background-color: $confemy-black;
}

.org-logo-edit-circle {
  width: 48px;
  height: 48px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colour-white;
  border-radius: 50%;

  position: absolute;
  transition: 300ms ease;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
