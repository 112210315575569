@import '../../utility/utility.styles.scss';

.myconfs-imgcard-wrap {
  padding: 8px;
  display: grid;
  grid-template-columns: 12.8rem auto;
  grid-template-rows: auto;
  column-gap: 16px;
  align-items: center;
  // &:hover {
  //   background-color: $shade-2;
  //   cursor: pointer;
  // }
  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
  }
}

.myconfs-img-wrap {
  height: 7.2rem;
  min-height: 7.2rem;
  max-height: 7.2rem;
  width: 12.8rem;
  min-width: 12.8rem;
  max-width: 12.8rem;
  object-fit: cover;
  background-color: '#fff';
  overflow: hidden;

  @media screen and (max-width: 650px) {
    display: none;
    // for below 650px, since we making display none, commenting other properties.
    // If one wants to show the image, then uncomment the properties and comment display none
    // height: 4.5rem;
    // min-height: 4.5rem;
    // max-height: 4.5rem;
    // width: 8rem;
    // min-width: 8rem;
    // max-width: 8rem;
    // margin-bottom: 8px;
  }
}

.myconfs-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.myconfs-no-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40;
  background-color: $shade-1;
  font-size: 16;
  border-radius: 0.4rem;
}

.myconfs-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-width: 300px;
}

.myconfs-card-header {
  font-family: Avenir-Heavy;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.22px;
  color: $confemy-black;

  @media screen and (max-width: 1200px) {
    font-size: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 16px;
  }
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.myconfs-card-body {
  @media screen and (max-width: 480px) {
    display: none;
  }
}
