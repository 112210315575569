@import '../../utility/utility.styles.scss';

.singleimage-upload-textbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-family: Avenir-Roman;
    // font-size: 20px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.01px;

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
    @media screen and (max-width: 400px) {
      font-size: 14px;
    }

    &:nth-child(odd) {
      opacity: 0.8;
      color: $gray-3;
    }
    &:nth-child(2) {
      margin: 0 8px;
      font-weight: 900;
      color: $confemy-black;
      opacity: 1;
    }
  }
}

.singleimage-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px;
  border-radius: 4px;
  border: solid 2px #ced9de;
  // background-color: $shade-2;
  background-color: #fff;
}

.singlefile-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px;
  border-radius: 4px;
  border: solid 2px #ced9de;
  // background-color: $shade-2;
  background-color: #fff;
}

.default-si-dz-content {
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}
