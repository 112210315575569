.oview-container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  color: #1c1d1f;
}

.oview-box {
  width: 100%;
  height: auto;
}

.oview-ttl {
  font-size: 2.4rem;

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
}

.oview-desc {
  margin: 1rem 0;
}

.oview-box1 {
  padding: 1rem 0;
  display: flex;
  gap: 1rem;
  border-top: 2px solid #ced9de;
}

.oview-sTtl {
  width: 100%;
  max-width: 25%;
}

.oview-sinBox {
  width: 100%;
}

.oview-item {
  margin: 0.5rem 0;
  display: flex;
  gap: 0.5rem;
}

.oview-ttlTxt {
  margin-bottom: 1rem;
}

.oview-link {
  font-weight: 500;
  margin: 0 0.5rem;
  color: #8710d8;
  text-underline-offset: 0.2rem;
}

.oview-instructorBox {
  display: flex;
  gap: 1rem;
}

.oview-instImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.oview-instTtl {
  font-size: 1.6rem;
}

.oview-instDesc {
  margin: 1rem 0;
  font-size: 1.6rem;
}

@media screen and (max-width: 426px) {
  .oview-outerDiv {
    padding: 0.5rem;
  }

  .oview-box1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .oview-sTtl {
    max-width: 100%;
  }
}
