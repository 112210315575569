@import "../../utility/utility.styles.scss";

.create-org-wrap {
  width: 514px;
  max-width: 100%;
  margin-left: 64px;
  // margin: 20px 0px 64px 64px;
  h2 {
    color: $primary;
  }
  @media screen and (max-width: 620px) {
    margin-left: 0px;
  }
}

.logo-upload-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 40px;
  @media screen and (max-width: 762px) {
    display: block;
  }
}

.logo-upload-textbox {
  width: 293px;
  margin-left: 24px;
  @media screen and (max-width: 762px) {
    margin-top: 20px;
    margin-left: 0px;
    width: auto;
  }
  span {
    font-family: Avenir-Roman;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.01px;

    &:nth-child(odd) {
      opacity: 0.8;
      color: $gray-3;
    }
    &:nth-child(2) {
      margin: 0 8px;
      font-weight: 900;
      color: $confemy-black;
      opacity: 1;
    }
  }
}

.logo-dropzone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 240px;
  padding: 50px;
  background-color: $shade-2;
  @media screen and (max-width: 632px) {
    width: auto;
  }
}

.socialmedia-wrap {
  input[type="text"] {
    padding-left: 80px;
    &:focus {
      border: solid 2px $info;
    }
  }
}

.savelogo-buttons-wrap {
  button {
    padding: 8px 8px;
    margin-top: 8px;
    margin-right: 8px;
  }
}
