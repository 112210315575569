.dashboardCreditGoal-progress {
  width: 100%;
  height: 3px;
  // margin-bottom: -5px;

  appearance: none;

  &::-webkit-dashboardcreditgoal-progress-value,
  &::-moz-progressdashboardcreditgoal-progress {
    background-color: #08415c;
  }

  &::-webkit-dashboardcreditgoal-progress {
    background-color: #d2eaf5;
  }
}

.dashboard-credit-goal {
  height: 200px;
  width: 100%;
  overflow-y: scroll; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  // background: #f2f2f2;
  //   border: 1px solid #e6e6e6;
  //   background: $info;
  color: #f2f2f2;
  padding: 16px;
  //   border-radius: 8px;
  margin: 0 30px 0 0;
  //   padding: 11px 35px 1px 16;
  border-radius: 6px;
  border: solid 1px #ecf0f2;
  background-color: #fff;

  @media screen and (max-width: 1240px) {
    margin-top: 20px;
  }
}
.dashboard-credit-goal::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

.dashboard-credit-goal::-webkit-scrollbar-thumb {
  background-color: #ced9de;
}
