:root {
  --font-stack-heading: 'Udemy Sans', -apple-system, BlinkMacSystemFont, Roboto,
    'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-stack-text: 'Udemy Sans', 'SF Pro Text', -apple-system,
    BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --color-blue-500: #180a3d;
  --color-blue-400: #371783;
  --color-blue-300: #5624d0;
  --color-blue-200: #c0c4fc;
  --color-blue-100: #eeeffc;
  --color-indigo-500: var(--color-blue-500);
  --color-indigo-400: var(--color-blue-400);
  --color-indigo-300: var(--color-blue-300);
  --color-indigo-200: var(--color-blue-200);
  --color-indigo-100: var(--color-blue-100);
  --color-yellow-500: #3d3c0a;
  --color-yellow-200: #eceb98;
  --color-green-500: #113731;
  --color-green-200: #acd2cc;
  --color-purple-300: #a435f0;
  --color-white: #fff;
}

.cart--cart-page-container--BEx2F {
  padding: 0 1.6rem;
  margin-bottom: 2.4rem;
  min-width: 32rem;
}

@media screen and (min-width: 43.81rem) {
  .cart--cart-page-container--BEx2F {
    margin: 0 auto;
    margin-bottom: 3.2rem;
    max-width: 134rem;
  }
}

@media screen and (min-width: 61.31rem) {
  .cart--cart-page-container--BEx2F {
    padding: 0 4.8rem;
    margin-bottom: 6.4rem;
  }
}

.cart--title-section--S7XdX {
  display: none;
}

@media screen and (min-width: 43.81rem) {
  .cart--title-section--S7XdX {
    display: flex;
  }
}

@media screen and (min-width: 61.31rem) {
  .cart--title-section--S7XdX {
    margin-top: 3.2rem;
  }
}

.cart--notifications-section--o9qC8 {
  margin-top: 1.6rem;
}

@media screen and (min-width: 61.31rem) {
  .cart--notifications-section--o9qC8 {
    margin-top: 3.2rem;
  }
}

.cart--shopping-section--Iyr6J {
  display: flex;
  margin-top: 2.4rem;
  flex-direction: column-reverse;
}

@media screen and (min-width: 43.81rem) {
  .cart--shopping-section--Iyr6J {
    flex-direction: row;
    margin-top: 3.2rem;
  }
}

// h1 {
//   font-size: inherit;
//   font-weight: 400;
//   max-width: 60rem;
// }

.ud-heading-xxxl {
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 4rem;
  max-width: 36em;
}

.cart--shopping-section--Iyr6J .cart--shopping-lists-pane--2KQk2 {
  flex: 1;
  margin-top: 3.2rem;
}

@media screen and (min-width: 43.81rem) {
  .cart--shopping-section--Iyr6J .cart--shopping-lists-pane--2KQk2 {
    margin-top: 0;
  }
}

.cart--shopping-section--Iyr6J .cart--checkout-pane---evXE {
  flex: 1;
}

@media screen and (min-width: 43.81rem) {
  .cart--shopping-section--Iyr6J .cart--checkout-pane---evXE {
    max-width: 30rem;
  }
}

@media screen and (min-width: 61.31rem) and (max-width: 75rem) {
  .cart--shopping-section--Iyr6J .cart--checkout-pane---evXE {
    margin-left: 4.8rem;
  }
}

// h3 {
//   font-size: inherit;
//   font-weight: 400;
//   max-width: 60rem;
// }

.ud-heading-md {
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 1.6rem;
}

.styles--shopping-list-title--inNls {
  margin-bottom: 0.8rem;
}

.total-price--total-checkout-container--vVoOI {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 0.8rem;
}

// button {
//   background-image: none;
// }

.ud-heading-sm {
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 1.4rem;
}

.ud-btn {
  position: relative;
  align-items: center;
  border: none;
  cursor: pointer;
  display: inline-flex;
  min-width: 8rem;
  padding: 0 1.2rem;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
}

.ud-btn-small {
  min-width: 8rem;
  height: 3.4rem;
}

.ud-btn-link-underline {
  color: #5624d0;
  background-color: transparent;
  min-width: auto;
  padding: 0;
}

.ud-btn-link-underline {
  height: auto;
  text-align: left;
  vertical-align: baseline;
  white-space: normal;
}

.ud-btn-link-underline {
  text-decoration: underline;
  text-underline-offset: 0.4rem;
  text-decoration-color: #8072e6;
}

.styles--sc-checkout-pane__toggle-coupon--QNuHS {
  color: #5624d0;
  margin: 1.6rem 0;
}

@media only screen and (min-width: 37.56rem) {
  .styles--sc-checkout-pane__toggle-coupon--QNuHS {
    display: none;
  }
}

.ud-btn-link-underline:active,
.ud-btn-link-underline:hover {
  color: #401b9c;
}

.styles--redeem-coupon-wrapper--xYbWm {
  margin-top: 1.6rem;
  border-top: 1px solid #d1d7dc;
  padding-top: 1.6rem;
}

.ul-booking {
  font-size: inherit;
  font-weight: 400;
  max-width: 60rem;
  padding-left: 2.4rem;
}

.ud-unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: none;
}

.shopping-item--shopping-list-same-buyable-type-wrapper--IG6bO {
  min-width: 100%;
}

.base-price-text-module--container--Sfv-5 {
  align-items: center;
  color: #2d2f31;
  display: flex;
  flex-flow: row wrap;
}

.total-price--total-checkout-container--vVoOI
  .total-price--container-desktop-layout--MbNsy {
  flex-direction: column;
  align-items: flex-start;
}

.ud-btn-large {
  height: 4.8rem;
}

.ud-btn-brand {
  color: var(--color-white);
}

.ud-btn-brand {
  background-color: var(--color-purple-300);
}

.checkout-button--checkout-button--X6zla {
  width: 100%;
}

.ud-btn-brand:hover {
  color: var(--color-white);
}

.ud-btn-brand:active,
.ud-btn-brand:hover {
  background-color: #8710d8;
}

.shopping-item--shopping-item-wrapper--hi8Eu {
  padding: 1.6rem 0;
  border-top: 1px solid #d1d7dc;
}

.total-price--total-label--kVPl7 {
  color: #6a6f73;
}

.total-price--total-checkout-container--vVoOI
  .total-price--container-desktop-layout--MbNsy
  > .total-price--total-label--kVPl7 {
  margin-bottom: 0.8rem;
}

.ud-heading-xxl {
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 3.2rem;
  max-width: 36em;
}

.base-price-text-module--price-part---xQlz {
  line-height: 1;
  padding: 0.4rem 0;
  margin-right: 0.8rem;
  white-space: nowrap;
}

.base-price-text-module--price-part---xQlz:last-child {
  margin-right: 0;
}

.redeem-coupon--add-coupon-button--1SH5F {
  display: flex;
  flex-direction: column;
}

// p {
//   font-size: inherit;
//   font-weight: 400;
//   max-width: 60rem;
// }

.redeem-coupon--title--2FKHW {
  margin-bottom: 1.6rem;
}

.redeem-coupon--codes--ZsK6n {
  color: #6a6f73;
  margin-bottom: 0.8rem;
}

.ud-form-group {
  min-width: 18rem;
  max-width: 60rem;
}

.coupon-form--coupon-form-group--BT1jw {
  max-width: none;
}

.shopping-item--container--8dCap {
  position: relative;
  display: grid;
  grid: 'image  header  price' auto 'image  rating  price' auto 'image  badges  price' auto 'image  meta    meta' auto 'image  actions actions' auto/auto 1fr auto;
  grid-auto-flow: row;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
}

.ud-sr-only {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.redeem-coupon--code--rtYw8 {
  margin-top: 0.8rem;
}

.redeem-coupon--code-in-checkout-pane--jhrEf {
  color: #6a6f73;
}

.redeem-coupon--codes--ZsK6n > div {
  border: 1px dashed #d1d7dc;
  display: flex;
  align-items: center;
}

.ud-form-label {
  display: flex;
  align-items: center;
  margin: 0;
  padding-bottom: 0.8rem;
  min-height: 2.8rem;
}

.text-input-form-module--text-input-form--tITHD {
  display: flex;
}

.shopping-item--image--carLV {
  grid-area: image;
}

.shopping-item--header--x5WKv {
  grid-area: header;
  min-width: 1px;
}

.shopping-item--badges--ylLzU {
  grid-area: badges;
  margin-top: 0.8rem;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.4rem 0.8rem;
}

.shopping-item--rating--311vG {
  grid-area: rating;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ud-text-xs {
  font-family: var(--font-stack-text);
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.2rem;
}

.shopping-item--meta--GHdaY {
  grid-area: meta;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex-wrap: wrap;
  margin-top: 0.8rem;
  color: #6a6f73;
}

.shopping-item--horizontal-actions--AkNvE {
  margin-top: 0.8rem;
  grid-area: actions;
  gap: 0 1.6rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.shopping-item--price-container--kLVfs {
  grid-area: price;
  padding-left: 4.8rem;
  display: flex;
}

.redeem-coupon--code--rtYw8 .redeem-coupon--code-text--ZuJBa {
  font-size: 1.4rem;
  float: left;
  height: 4.8rem;
  width: 100%;
  align-items: center;
  display: flex;
}

.ud-btn-ghost {
  color: #5624d0;
  background-color: transparent;
  min-width: auto;
  padding: 0;
}

.ud-btn-icon {
  min-width: auto;
}

.ud-btn-icon-large {
  padding: 0 1.2rem;
  width: 4.8rem;
}

.redeem-coupon--code--rtYw8 .redeem-coupon--code-icon-button--nwf7w {
  height: 4.8rem;
}

.ud-btn-ghost:active,
.ud-btn-ghost:hover {
  color: #401b9c;
}

// input {
//   background-image: none;
// }

// input {
//   color: #2d2f31;
// }

.ud-text-sm {
  font-family: var(--font-stack-text);
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.4rem;
}

.ud-text-input {
  border: 1px solid #2d2f31;
  border-radius: 0;
  -webkit-appearance: none;
  display: block;
  padding: 0 1.6rem;
  width: 100%;
}

.ud-text-input-small {
  height: 3.4rem;
}

.text-input-form-module--text-input-form--tITHD input {
  border-right: 0;
  flex: 1;
}

.shopping-item--container--8dCap .shopping-item--image-wrapper--axQKA {
  margin-right: 0.8rem;
}

.shopping-item--image--carLV .shopping-item--image-wrapper--axQKA {
  background-color: #2d2f31;
  border: 1px solid #d1d7dc;
  overflow: hidden;
  position: relative;
}

.shopping-item--header--x5WKv .shopping-item--course-title--fHyfq {
  display: block !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media (min-width: 61.31rem) {
  .shopping-item--header--x5WKv .shopping-item--course-title--fHyfq {
    display: block !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

.shopping-item--header--x5WKv .shopping-item--instructors--nARja {
  display: none;
}

@media (min-width: 61.31rem) {
  .shopping-item--header--x5WKv .shopping-item--instructors--nARja {
    display: block;
  }
}

.ud-heading-xs {
  font-family: var(--font-stack-heading);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02rem;
  font-size: 1.2rem;
}

.ud-badge {
  display: inline-block;
  padding: 0.4rem 0.8rem;
  white-space: nowrap;
}

.course-badges-module--bestseller--JKaT4 {
  background-color: var(--color-yellow-200);
  color: var(--color-yellow-500);
}

.course-badges-module--updated-recently--uwYaM {
  background-color: var(--color-green-200);
  color: var(--color-green-500);
}

.star-rating-module--star-wrapper--i1cJH {
  display: inline-flex;
  align-items: center;
}

.shopping-item--rating--311vG .shopping-item--reviews-text--9UDy- {
  color: #6a6f73;
  margin-left: 0.4rem;
}

.shopping-item--meta--GHdaY > .shopping-item--meta-item--bVx-I {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ud-icon {
  fill: currentColor;
  display: inline-block;
  flex-shrink: 0;
}

.ud-icon-xxsmall {
  width: 1.4rem;
  height: 1.4rem;
}

.ud-icon-color-subdued {
  color: #6a6f73;
}

.ud-btn-xsmall {
  min-width: 8rem;
  padding: 0 0.8rem;
  height: 2.8rem;
}

.shopping-item--price--HU-yc {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.shopping-item--price-discounted-styles--4-IFv {
  color: #a435f0;
}

.popper-module--popper--mM5Ie {
  position: relative;
}

.shopping-item--coupon-icon-tooltip--7V7UN {
  display: inline-block;
  padding-top: 0.4rem;
  margin-left: 0.4rem;
  height: max-content;
}

.redeem-coupon--code--rtYw8 .redeem-coupon--code-text--ZuJBa > ul {
  list-style: none;
  padding-left: inherit;
  font-size: 1.2rem;
}

.ud-icon-small {
  width: 2rem;
  height: 2rem;
}

.ud-icon-color-neutral {
  color: #2d2f31;
}

.booking-course-img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.shopping-item--image--carLV .shopping-item--course-image--4uk7C {
  display: block;
  transition: opacity linear 100ms;
  object-fit: cover;
  width: 4.8rem;
  height: 4.8rem;
}

@media (min-width: 61.31rem) {
  .shopping-item--image--carLV .shopping-item--course-image--4uk7C {
    width: 12rem;
    height: 6.8rem;
  }
}

// a {
//   color: #5624d0;
//   text-decoration: none;
// }

.shopping-item--container--8dCap .shopping-item--course-title--fHyfq a {
  color: #2d2f31;
}

.shopping-item--container--8dCap
  .shopping-item--course-title--fHyfq
  a[href]::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// a:hover {
//   color: #401b9c;
// }

.shopping-item--header--x5WKv .shopping-item--instructor-list---LObJ {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0.4rem;
}

.star-rating-module--rating-number--2-qA2 {
  margin-right: 0.4rem;
  color: #4d3105;
}

.star-rating-module--star-wrapper--i1cJH.star-rating-module--medium--Lpe62
  > svg {
  display: block;
  width: 7rem;
  height: 1.6rem;
}

.shopping-item--price-text--9T8LT {
  margin-right: 0;
}

.ud-icon-xsmall {
  width: 1.6rem;
  height: 1.6rem;
}

.popper-module--popper-content--XE9z5 {
  display: none;
  position: absolute;
  z-index: 1000;
}

// li {
//   padding-left: 0.8rem;
// }

// li + li {
//   margin-top: 0.4rem;
// }

.redeem-coupon--code--rtYw8 .redeem-coupon--code-text--ZuJBa > ul > * + * {
  margin-top: 0;
}

.star-rating-module--star-filled--FgjdR {
  fill: #b4690e;
}

.star-rating-module--star-bordered--A4SZK {
  stroke: #b4690e;
}

.popper-module--animation-wrapper--tM0UK {
  opacity: 0;
  animation: popper-module--pop-in--UYCRb 100ms cubic-bezier(0.2, 0, 0.38, 0.9)
    forwards;
}

.redeem-coupon--code--rtYw8 .redeem-coupon--code-text--ZuJBa > ul > li b {
  font-size: 1.4rem;
}

.tooltip-module--tooltip--zlsMw {
  background-color: #2d2f31;
  border: 1px solid #6a6f73;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  color: #fff;
  padding: 0.8rem;
  max-width: 24rem;
  width: max-content;
}

.tooltip-module--white--rM-Ra {
  background-color: #fff;
  border-color: #d1d7dc;
  color: #2d2f31;
}

@keyframes popper-module--pop-in--UYCRb {
  0% {
    opacity: 0;
    transform: scale(0.9);
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    opacity: 1;
    transform: scale(1);
  }
}
/* These were inline style tags. Uses id+class to override almost everything */
#u9-popper-content--28.style-gFjx5 {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}
