@import "../../utility/utility.styles.scss";

// This pagination wrap matches class .sr-container size and breakpoints
// .pagination-wrap {
//   margin-top: 3.2rem;
//   margin-bottom: 1.6rem;
//   // width: 88.8rem;
//   @media screen and (max-width: 1250px) {
//     width: 87.2rem;
//   }
//   @media screen and (max-width: 900px) {
//     width: 59.2rem;
//   }
//   @media screen and (max-width: 600px) {
//     width: 28rem;
//   }
// }

.pagination-wrap {
  width: 88.8rem;
  max-width: 100%;
  @media screen and (max-width: 1250px) {
    width: 87.2rem;
  }
  @media screen and (max-width: 900px) {
    width: 59.2rem;
  }
  @media screen and (max-width: 600px) {
    width: 28rem;
  }
  @media screen and (max-width: 400px) {
    margin: 0 auto;
  }
}

.pagination-wrap {
  .pagination {
    justify-content: flex-end;
    @media screen and (max-width: 400px) {
      justify-content: center;
    }
  }
}

.pagination {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 0px;
}

.pagination span {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  margin: 2px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    padding: 5px 10px;
  }
}

.pagination span.active {
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
}

.pagination span:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}
