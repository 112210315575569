@import '../../utility/utility.styles.scss';

.db-confs-table-wrap {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ced9de;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    @include avenir-heavy-18;
    color: $gray-3;
    // border: 1px solid $shade-1;
    // border-radius: 8px;
    font-size: 13px;
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
    thead {
      tr {
        background-color: $shade-2;
        border: 1px solid $shade-1;
        border-radius: 8px;
        th {
          padding: 8px;
          text-align: left;
          //   &:last-child {
          //     text-align: center;
          //   }
        }
      }
    }
    tbody {
      vertical-align: top;
      td {
        padding: 8px;
        text-align: left;
        color: $gray-2;
      }

      //   tr {
      // & > td:last-child {
      //   text-align: center;
      // }
      // background-color: $shade-2;
      // border: 1px solid $background;

      // every odd row is backcolor white
      // &:nth-child(odd) {
      //   background-color: #fff;
      // }

      // first td of every row as there are 4 tds in each row
      // & > td:nth-child(4n + 1) {
      //   color: $confemy-black;
      // }

      // 5th td of every row as there are 5 tds in each row
      // & > td:nth-child(5n + 5) {
      //   text-align: right;
      // }
      // &:hover {
      //   background-color: $shade-2;
      //   cursor: grab;
      // }
      // &:active {
      //   background-color: $background;
      // }
      //   }
    }
  }
}

.db-confs-table-wrap::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

.db-confs-table-wrap::-webkit-scrollbar-thumb {
  background-color: #ced9de;
}
