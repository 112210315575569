.get-started-wrap {
  padding: 108px 40px;
  text-align: center;
  h1 {
    color: white;
  }

  @media screen and (max-width: 600px) {
    padding: 60px 20px;
  }
}
