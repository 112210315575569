@import '../../utility/utility.styles.scss';

.tabs-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid $shade-4;
}

.podcast-tabs-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  // justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 474px) {
    display: block;
    text-align: center;
  }
  // border-bottom: 1px solid $shade-4;
}

.podcast-res-tabs-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  // justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 474px) {
    display: block;
    text-align: center;
  }
  // border-bottom: 1px solid $shade-4;
}

.inactive-shared-tab {
  padding-bottom: 1.6rem;
  border-bottom: solid 0.4rem $colour-white;
  @include caption-1-heavy;
  color: #757575;
}
.inactive-res-shared-tab {
  padding-bottom: 1.6rem;
  // border-bottom: solid 0.4rem $colour-white;
  @include caption-1-heavy;
  color: #757575;
}

.active-res-shared-tab {
  border-bottom-color: #fff;
  color: #fff;
  font-weight: 900;
}

.active-shared-tab {
  border-bottom-color: $primary;
  color: $primary;
  font-weight: 900;
}

.active-res-shared-tab {
  border-bottom: solid 0.4rem $colour-white;
  color: #fff;
  font-weight: 900;
}

.completed-active-shared-tab {
  color: #4cb944;
  border-bottom-color: #4cb944;
  font-weight: 900;
}

.completed-inactive-shared-tab {
  color: #4cb944;
}

.tabs-margin {
  margin-right: 32px;

  @media screen and (max-width: 460px) and (min-width: 411px) {
    margin-right: 26px;
  }
}

.podcast-tabs-margin {
  // margin-right: 32px;
  // margin-left: 10px;

  @media screen and (max-width: 460px) and (min-width: 411px) {
    margin-right: 26px;
  }
}

@media screen and (max-width: 410px) and (min-width: 360px) {
  .tabs-margin {
    margin-right: 12px;
  }
}

@media screen and (max-width: 360px) {
  .tabs-margin {
    margin-right: 8px;
  }
}
