@import "../../utility//utility.styles.scss";

.show-organizers-grid {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 6px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;
  & > div:first-child {
    justify-self: start;
    max-width: 380px;
  }

  & > div:nth-child(2) {
    justify-self: end;
  }

  & > div:last-child {
    justify-self: end;
  }
  p {
    font-family: Avenir-Heavy;
  }

  @media screen and (max-width: 560px) {
    & > div:first-child {
      max-width: 340px;
    }
  }

  @media screen and (max-width: 520px) {
    grid-template-columns: 1fr;
    & > div:first-child {
      max-width: 300px;
    }
    & > div:nth-child(2) {
      justify-self: start;
    }
    & > div:last-child {
      justify-self: start;
    }
  }
}

.social-delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: $shade-1;
  }
}
