// cc is confemy credit
.ec-grid-table {
  grid-template-columns: 1fr 1fr auto 1fr auto !important;
  border: none !important;
  box-shadow: none !important;
  background-color: #fff !important;
  margin-bottom: 0px !important;
  li {
    &:nth-child(-n + 5) {
      background-color: #eef1f5 !important;
    }
  }
}

.credit-flex-vc-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 489px) {
    display: block;
  }
}
