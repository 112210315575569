@import '../../utility/utility.styles.scss';

// mc is media content

.mc-details {
  border: 1px solid $shade-1;
  padding: 0.4rem 0rem;
  // background-color: #fff;
  background-color: $shade-3;
  border-radius: 4px;
}

.mc-summary {
  // margin: -0.5em -0.5em 0;
  // border: 2px solid red;
  padding: 1.2rem;
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mc-summaryDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mc-section-title {
  font-size: 1.6rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mc-section-details {
  font-size: 1.6rem;
  font-weight: 500;
  span:last-child:before {
    content: ' · ';
    font-weight: 800;
  }
}

.mc-summary::before {
  content: '';
  display: inline-block;
  margin-right: 1rem;
  padding-left: 0.3rem;
  height: 10px;
  width: 10px;
  transition: all 0.5s;
  background-image: url('../../assets/down-arrow.svg');
  background-repeat: no-repeat;
}

.mc-details[open] {
  .mc-summary {
    border-bottom: 1px solid $shade-1;
    padding-bottom: 1.6rem;
    &::before {
      height: 10px;
      width: 10px;
      transform: rotate(-180deg);
      background-image: url('../../assets/down-arrow.svg');
      background-repeat: no-repeat;
    }
  }
}

.mc-episode {
  width: 100%;
  padding: 0rem 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.6rem;
  gap: 0.8rem;
}

.mc-episode-left,
.mc-episode-center,
.mc-episode-right {
  display: flex;
  align-items: center;
}

.mc-episode-center {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.dark-play-icon {
  width: 15px;
  height: 15px;
}
