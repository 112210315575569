@import "../../utility//utility.styles.scss";

// conf booking by attendee page
// cbbap

.cbbap-container {
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 16px;

  @media screen and (max-width: 800px) {
    grid-template-columns: auto;
  }
}

.cbbap-paybox {
  border-left: 1px solid #ced9de;
  padding-left: 40px;
  @media screen and (max-width: 800px) {
    border-top: 1px solid #ced9de;
    padding-top: 32px;
    border-left: none;
    padding-left: 0px;
  }
}
