@import "../../utility//utility.styles.scss";

// sp is search page
.sp-container {
  width: 100%;
  min-height: calc(100vh - 6.4rem);
  display: flex;

  @media screen and (max-width: 1163px) {
    position: relative;
  }
}

// container-off-sf ie container off search filters

.container-off-sf {
  max-width: 100%;
  flex-grow: 1;
  padding: 6.4rem 2.4rem 4rem 7.2rem;

  @media screen and (max-width: 1324px) {
    padding-left: 4rem;
    padding-bottom: 50rem;
  }

  @media screen and (max-width: 1250px) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

// sb is search bar
.sb-container {
  display: flex;
  overflow: hidden;
}

.sr-tabs-container {
  display: grid;
  // width: 100%;
  max-width: 100%;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: auto;
  gap: 4rem;
  border-bottom: 1px solid $shade-1;
  padding: 2.4rem 1.6rem 0rem;

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(5, auto);
    gap: 1.6rem;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(3, auto);
    padding: 2rem 0rem 0rem;
    gap: 1rem;
  }

  @media screen and (max-width: 370px) {
    grid-template-columns: repeat(2, auto);
  }
}

.active-search-type {
  width: max-content;
  padding-bottom: 1.6rem;
  border-bottom: 3px solid $primary;
  @include caption-1-heavy;
  font-weight: 900;
  color: $primary;

  @media screen and (max-width: 350px) {
    font-size: 14px;
  }
}

.inactive-search-type {
  width: max-content;
  padding-bottom: 1.6rem;
  @include caption-1-heavy;
  color: $gray-2;
  @media screen and (max-width: 350px) {
    font-size: 14px;
  }
}

// sr is search result
.sr-container {
  // border: 2px solid black;
  position: relative;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  // width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 28rem);
  grid-template-rows: auto;
  gap: 2.4rem;

  @media screen and (max-width: 1250px) {
    gap: 1.6rem;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 28rem);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 28rem);
  }
  @media screen and (max-width: 400px) {
    justify-content: center;
  }
}

.sr-container-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  border-radius: 4px;
  background-color: $colour-white;
}

.mobile-sf-button {
  display: none;
  @media screen and (max-width: 1163px) {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 98;
    top: 0;
    left: 0;
    right: 0;

    height: 4.8rem;
    padding: 0rem 1.6rem;
    margin-bottom: 1.6rem;

    & > i {
      cursor: pointer;
    }
    & > h4 {
      cursor: pointer;
    }
  }
}

.sf-drawer {
  display: none;
  @media screen and (max-width: 1163px) {
    display: block;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    bottom: 0;
    // cursor: pointer;
    width: 28.6rem;
    min-width: 28.6rem;
    min-height: calc(100vh - 6.4rem);
    border: 1px solid $shade-1;
    // border-left: 1px solid $shade-1;
    // border-bottom: 1px solid $shade-1;
    background-color: $background;
  }
  @media screen and (max-width: 350px) {
    width: 27rem;
    min-width: 27rem;
  }
}

// sr container for pagination
