// tc trending confs

.tc-container {
  width: 100%;
  margin: 0 auto;
  padding: 4rem 4.4rem;
  max-width: 136rem;
  @media screen and (max-width: 680px) {
    padding: 0 3.2rem;
  }
  @media screen and (max-width: 650px) {
    text-align: center;
  }
  @media screen and (max-width: 350px) {
    padding: 0 2rem;
  }
}
