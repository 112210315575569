@import './utility.styles.scss';

// buttons

@mixin button-text {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  // line-height: 1;
  letter-spacing: 0.41px;
  text-align: center;
  color: $colour-white;
}

.button {
  display: inline-block;
  outline: none;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.12s ease-in;
  min-width: 1.6rem;
  padding: 1.6rem 3.2rem;
  border-radius: 4px;
  @include button-text;

  &.button-green {
    background: $accent;
    border-color: $accent;
    &:hover {
      background-color: #45a93e;
      border-color: #45a93e;
    }
    &:active {
      background-color: #3c9436;
      border-color: #3c9436;
    }
  }
  &.button-secondary {
    background: $shade-4;
    border-color: $shade-4;
    &:hover {
      background: $shade-2;
      border-color: $shade-2;
    }
  }

  &.button-primary {
    background: $primary;
    border-color: $primary;
    &:hover {
      background: #073b54;
      border-color: #073b54;
    }
    &:active {
      background-color: #063449;
      border-color: #063449;
    }
  }

  &.button-red {
    background: $warning;
    border-color: $warning;
    &:hover {
      background: $warning;
      border-color: $warning;
    }
    &:active {
      background-color: $warning;
      border-color: $warning;
    }
  }
  // @media screen and (max-width: 500px) {
  //   font-size: 12px;
  //   font-weight: 700;
  //   padding: 1rem 2rem;
  // }
}

.button-text {
  display: inline-block;
  border: 2px solid $shade-3;
  outline: none;
  cursor: pointer;
  transition: all 0.12s ease-in;
  background-color: $shade-3;
  @include button-text;

  min-width: 1rem;
  padding: 1.8rem 3.4rem;
  border-radius: 4px;

  &.button-text-primary {
    color: $primary;

    &:hover {
      background: $shade-1;
      border: 2px solid $shade-1;
    }
  }

  &.button-text-red {
    color: $warning;

    &:hover {
      color: #c6000b;
      background-color: #fdf2f2;
      //   border-color: #c6000b;
    }
    &:active {
      color: #ac0009;
      //   border-color: #ac0009;
    }
  }
}

.button-outlined {
  display: inline-block;
  outline: none;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.12s ease-in;
  min-width: 10rem;
  padding: 1.6rem 3.2rem;
  border-radius: 4px;
  @include button-text;
  background-color: $background;

  &.button-outlined-primary {
    color: $primary;
    border-color: $primary;

    &:hover {
      color: #073b54;
      border-color: #073b54;
    }
    &:active {
      color: #063449;
      border-color: #063449;
    }
  }

  &.button-outlined-green {
    color: $accent;
    border-color: $accent;

    &:hover {
      color: #45a93e;
      border-color: #45a93e;
    }
    &:active {
      color: #3c9436;
      border-color: #3c9436;
    }
  }

  &.button-outlined-red {
    color: $warning;
    border-color: $warning;

    &:hover {
      color: #c6000b;
      border-color: #c6000b;
    }
    &:active {
      color: #ac0009;
      border-color: #ac0009;
    }
  }
}

// create conference form  buttons

.button-outlined-inactive {
  display: inline-block;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  // cursor: not-allowed;
  // pointer-events: all !important;
  transition: all 0.12s ease-in;
  min-width: 10rem;
  padding: 16px 32px;
  border-radius: 4px;
  // background-color: #fff;
  // text style

  font-family: Avenir-Roman;
  opacity: 0.6;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: center;
  color: $confemy-black;

  // &:hover {
  //   color: #45a93e;
  //   border-color: #45a93e;
  // }
}

.button-outlined-active {
  display: inline-block;
  outline: none;
  cursor: pointer;
  transition: all 0.12s ease-in;
  min-width: 10rem;
  padding: 18px 34px;
  border-radius: 4px;
  // border: 1px solid rgba(0, 0, 0, 0.2);
  // background-color: #fff;
  border: solid 1px #00529b;
  background-color: $shade-2;

  // text styles

  opacity: 0.87;
  font-family: Avenir-Heavy;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: center;

  color: #00529b;
  // @media screen and (max-width: 430px) {
  //   margin: 1rem 0;
  // }
}

.small-button {
  @include button-text();
  display: inline-block;
  outline: none;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.12s ease-in;
  min-width: 6.4rem;
  padding: 0.8rem 0.8rem;
  border-radius: 4px;

  &.small-button-primary {
    background: $primary;
    border-color: $primary;
    &:hover {
      background: #073b54;
      border-color: #073b54;
    }
    &:active {
      background-color: #063449;
      border-color: #063449;
    }
  }

  &.small-button-green {
    background: $accent;
    border-color: $accent;
    &:hover {
      background-color: #45a93e;
      border-color: #45a93e;
    }
    &:active {
      background-color: #3c9436;
      border-color: #3c9436;
    }
  }
}
// smal button outlined

.small-button-outlined {
  display: inline-block;
  outline: none;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.12s ease-in;
  min-width: 6.4rem;
  padding: 0.8rem 0.8rem;
  border-radius: 4px;
  @include button-text;
  background-color: $background;

  &.small-button-outlined-primary {
    color: $primary;
    border-color: $primary;

    &:hover {
      color: #073b54;
      border-color: #073b54;
    }
    &:active {
      color: #063449;
      border-color: #063449;
    }
  }

  &.small-button-outlined-green {
    color: $accent;
    border-color: $accent;

    &:hover {
      color: #45a93e;
      border-color: #45a93e;
    }
    &:active {
      color: #3c9436;
      border-color: #3c9436;
    }
  }
}
// small text button
.small-button-text {
  display: inline-block;
  border: 2px solid $shade-3;
  outline: none;
  cursor: pointer;
  transition: all 0.12s ease-in;
  background-color: $shade-3;
  @include button-text;
  min-width: 6.4rem;
  padding: 0.8rem;
  border-radius: 4px;

  &.small-button-text-primary {
    color: $primary;
    &:hover {
      background: $shade-1;
      border: 2px solid $shade-1;
    }
    &:active {
      color: #063449;
    }
  }

  &.small-button-text-green {
    color: $accent;

    &:hover {
      color: #45a93e;
    }
    &:active {
      color: #3c9436;
    }
  }

  &.small-button-text-red {
    color: $warning;
    &:hover {
      color: #c6000b;
      background-color: #fdf2f2;
      //   border-color: #c6000b;
    }
    &:active {
      color: #ac0009;
      //   border-color: #ac0009;
    }
  }
}
// see all button on home page
.see-all-button {
  margin-top: 4.6rem;
  margin-bottom: 9.6rem;
  text-align: center;
}

.pure-text-button {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.12s ease-in;
  background-color: transparent;
  @include button-text;
  min-width: 6.4rem;
  color: #8710d8;
}

.preview-button {
  color: $dark-purple;
  text-decoration: underline;
}
