@import "../../utility//utility.styles.scss";

.contact-us-form-wrap {
  width: 90%;
  margin: 0px auto;
  padding: 4% 10%;
  background-color: $shade-2;
  box-shadow: 5px 10px 10px 2px $gray-1;

  @media screen and (max-width: 600px) {
    padding: 5%;
  }
}
