@import "../../utility/utility.styles.scss";

.register-modal {
  width: auto;
  margin: 0 15px;
  .grid-col-2 {
    grid-gap: 0.8rem;
  }
}

.inactive-email {
  width: 100%;
  height: 64px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: $shade-1;
  margin-bottom: 2.4rem;
  @include caption-1-regular;
  color: $gray-3;
  overflow: auto;
}

.back-to-more-sign-up {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  @include arial-16;
  color: $primary;
  cursor: pointer;
}
