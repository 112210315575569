
.explore-section-1 {
  grid-column: 2/4;
  text-align: center;
  cursor: pointer;
  @media screen and (max-width:750px) {
    grid-column: 1/9;
    
  }
}

.explore-section-2 {
  grid-column: 4/6;
  text-align: center;
  cursor: pointer;
  @media screen and (max-width:750px) {
    margin: 3.2rem ;
    grid-column: 1/9;
    
  }
}

.explore-section-3 {
  grid-column: 6/8;
  text-align: center;
  cursor: pointer;
  @media screen and (max-width:750px) {
    grid-column: 1/9;
    
  }
}



.globe-sketch {
  width: 9.4rem;
  height: 13.5rem;
}

.videoconference-sketch {
  width: 13.2rem;
  height: 13.2rem;
}

.creditcards-sketch {
  width: 13.3rem;
  height: 13.3rem;
}

@media screen and (max-width:600px) {
  .explore-section-1,
  .explore-section-2,
  .explore-section-3{
    padding: 10px;
  }
}
