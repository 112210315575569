@import '../../utility//utility.styles.scss';

// md is media details

.md-top-container {
  width: 100%;
  padding: 2.4rem 0rem;
  background-color: $primary;
  color: #fff;
}

.video-preview-top-container {
  width: 100%;
  padding: 1.4rem 0rem;
}

.md-bottom-container {
  width: 100%;
  padding: 4rem 0rem;
}

.md-grid {
  // border: 1px solid black;
  width: 80%;
  margin: 0 auto;
  justify-content: end;
  // justify-items: center;
  display: grid;
  column-gap: 8rem;
  grid-template-columns: 1fr auto;
  @media screen and (max-width: 800px) {
    display: block;
  }
}

.media-cr-detail-card {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.podcast-cr-detail-card {
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.media-res-details-con {
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
  }
}
