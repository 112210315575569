@import "../../utility/utility.styles.scss";
// Alerts

.alert-container {
  position: sticky;
  // position: absolue;
  // z index is one more than navbar
  z-index: 100;
  // Putting it just below navbar which is 64px in height.
  top: 64px;
  width: 100%;
  max-width: $containerWidth;
  // align alert container in the center like navbar
  margin: 0 auto;
}
.alert {
  // width is 40% of the alert container
  width: 40%;
  //  align it on the right side
  margin-left: auto;
  padding: 0.4rem 1.2rem;
  // opacity: 0.8;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include caption-2-regular;

  &.alert-primary {
    background-color: $primary;
    color: $colour-white;
  }
  &.alert-dark {
    // background-color: $shade-1;
    background-color: #ffce32;
    color: $primary;
  }
  &.alert-success {
    background-color: $accent;
    color: $colour-white;
  }
  &.alert-danger {
    // background-color: $warning;
    background-color: #ff4f58;
    color: $colour-white;
  }
  &.alert-info {
    background-color: $info;
    color: $colour-white;
  }
  @media screen and (max-width: 650px) {
    width: 80%;
  }
}

@media screen and (max-width: 1110px) and (min-width: 650px) {
  .alert {
    width: 60%;
  }
}
