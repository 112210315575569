@import '../../utility/utility.styles.scss';

.dialogue-background {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $modal-background;
  z-index: 100;
}

.dialogue-container {
  // text-align: center;
  width: 450px;
  // width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: $colour-white;
  padding: 32px;
  border-radius: 4px;
  @media screen and (max-width: 450px) {
    width: 300px;
  }
}

.dialogue-title {
  width: 100%;
  margin-top: 16px;
}

.dialogue-body {
  width: 100%;
  flex-grow: 1;
  margin: 16px 0 32px;
  font-size: 18px;
}

.dialogue-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px 0;
}
