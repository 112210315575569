@import '../../utility//utility.styles.scss';
.schedule-gridtable {
  grid-template-columns: auto auto;
}

@each $num in 2 {
  @each $px in 500 {
    .schedule-grid-table-#{$num}-#{$px} {
      background-color: #eef1f5;
      width: max(#{$px}px, 100%);
      margin-bottom: 24px;
      display: grid;
      grid-template-columns: auto 1fr;
      @include avenir-heavy-16;
      // @include caption-1-medium;
      color: $gray-2;
      border: 1px solid #ced9de;
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
      align-items: center;
      // background: #fff;
      overflow-x: auto; /* Allow horizontal scrolling */

      li {
        list-style: none;
        height: 100%;
        padding: 1.2rem 1.6rem;

        // selecting the first row
        &:nth-child(-n + #{$num}) {
          background-color: $shade-1;
          color: $gray-3;
          padding: 1.6rem 1.6rem;
        }
        // selecting the last column
        &:nth-child(#{$num}n + #{$num}) {
          //   text-align: right;
        }
        // selecting every item after first header row
        &:nth-child(n + #{$num + 1}) {
          overflow: hidden;
          overflow-wrap: break-word;
        }
      }
      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
}

.schedule-scrollbar-grid-table-wrap {
  width: 100%;
  height: 800px;
  border: 1px solid #ced9de;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  overflow: auto;
  // hide scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.schedule-print-scrollbar-grid-table-wrap {
  width: 100%;
  border: 1px solid #ced9de;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  overflow: auto;
  // hide scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.s-grid-table {
  grid-template-columns: 1fr 1fr;
  border: none !important;
  box-shadow: none !important;
  background-color: #fff !important;
  li {
    &:nth-child(-n + 5) {
      background-color: #eef1f5 !important;
    }
  }
}

.schedule-sort {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  grid-template-rows: auto;
  gap: 7px;
  // small screen view
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

.scheduleView-sort {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto;
  gap: 7px;
  // small screen view
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

.lecture-title,
.lecture-block {
  page-break-inside: avoid;
}

.feedback-schedule-grid-table {
  width: max(500px, 100%);
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  @include avenir-heavy-16;
  /* @include caption-1-medium; */
  color: $gray-2;
  border: 1px solid #ced9de;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  align-items: center;
  /* background: #fff; */
  overflow-x: auto; /* Allow horizontal scrolling */

  li {
    list-style: none;
    height: 100%;
    padding: 1.2rem 1.6rem;

    /* Styling for the first row */
    &:nth-child(-n + 3) {
      background-color: $shade-1;
      color: $gray-3;
      padding: 1.6rem 1.6rem;
    }

    /* Styling for the last column */
    &:nth-child(3n + 3) {
      /* Optional: Add text alignment or other styles */
      /* text-align: right; */
    }

    /* Styling for every item after the first header row */
    &:nth-child(n + 4) {
      overflow: hidden;
      overflow-wrap: break-word;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.schedule-buttons-container {
  flex: 0 0 250px; /* Fixed width for buttons */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .schedule-buttons-container {
    flex: 0 0 160px;
    flex-direction: column; /* Stack buttons vertically */
    width: 100%; /* Full width for the buttons container */
  }

  .schedule-buttons-container div {
    margin-bottom: 10px;
    width: 100%; /* Make buttons take the full width */
  }
}
