@import "../../utility/utility.styles.scss";

.navbar-container {
  width: 100%;
  height: 6.4rem;
  position: fixed;
  z-index: 99;
  top: 0;
}

.navbar-responsive {
  width: 100%;
  max-width: $containerWidth;
  height: 6.4rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 0.2fr;
  background-color: $colour-white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  align-items: center;

  @media screen and (min-width: 600.1px) and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
}

.navbar-left-item {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: 8px;
}

.navbar-mid-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (min-width: 600.1px) and (max-width: 700px) {
    padding-right: 1.6rem;
  }
}

.navbar-right-item {
  display: flex;
  align-items: center;
  padding: 0px 2rem 0px 1.4rem;

  @media screen and (max-width: 960px) {
    padding-right: 1.6rem;
  }

  @media screen and (max-width: 770px) {
    padding-left: 0.6rem;
  }

  @media screen and (min-width: 600.1px) and (max-width: 700px) {
    display: none;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 2rem;
  @media screen and (max-width: 960px) {
    padding-left: 0.8rem;
  }
}

.logo {
  height: 3.6rem;
  @media screen and (max-width: 960px) {
    height: 2.88rem;
    width: 9.0032rem;
  }
}

.search-box {
  display: flex;
  align-items: center;
  margin-left: 2.4rem;
  padding: 1rem 4rem 1rem 2rem;
  border-radius: 6px;
  background-color: $shade-2;
  white-space: nowrap;

  @media screen and (max-width: 960px) {
    margin-left: 1.6rem;
  }

  @media screen and (max-width: 750px) {
    margin-left: 0.8rem;
    padding: 1rem 3rem 1rem 2rem;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.explore-conferences {
  font-family: Avenir-Roman;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  color: $confemy-black;
}

.create-conference {
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  color: $accent;
  white-space: nowrap;
}

.signin {
  display: inline-block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  color: $primary;
}

.auth-cart {
  display: none;
}

.location-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.location {
  display: flex;
  font-family: Avenir-Roman;
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  color: $primary;
  border-left: 1px solid #00000033;
  padding-left: 1.2rem;
  cursor: pointer;

  @media screen and (max-width: 770px) {
    padding-left: 0.8rem;
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.location-text {
  margin-left: 1.2rem;
  font-family: Avenir-Roman;
  font-size: 1.6rem;

  &-drop {
    margin-left: 0;
    margin-right: 1.2rem;
  }
}

.user-name-wrapper {
  display: flex;
  align-items: center;
  justify-content:flex-start &:hover {
    cursor: pointer;
  }
  svg {
    margin-left: 0.6rem;
  }
  &:hover {
    cursor: pointer;
  }
}

// use ~ for sibling/grandchild element. Dont use ~ for child element
// to use hover to show and hide a element
.hamburger-button {
  display: none;
  justify-content: end;
  align-items: center;
  margin-top: 0.6rem;
  margin-left: 1rem;

  button {
    background-color: $colour-white;
    border: none;
  }
}

.nav-menu {
  width: 27rem;
  position: fixed;
  z-index: 1;
  overflow: auto;
  top: 6.4rem;
  right: 0;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #fafbfc;
  text-align: left;
  @media screen and (min-width: 600.1px) {
    display: none;
  }
}

.auth-dropdown-small {
  width: 28rem;
  max-height: calc(100vh - 6.4rem);
  position: absolute;
  z-index: 1;
  overflow: auto;
  top: 6.4rem;
  right: 0;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.08);
  background-color: $colour-white;

  ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    li {
      position: relative;
      flex-grow: 1;
      display: flex;
      align-items: center;
      width: 100%;
      // min-height: 6.6rem;
      min-height: 5.2rem;

      a {
        @include body-regular-a-gray3;
      }

      svg {
        position: relative;
        top: 0.2rem;
        margin-left: 2.4rem;
        margin-right: 2.4rem;
      }

      &:hover {
        background-color: $shade-2;
      }
    }
  }
}

.cart-small {
  margin-top: 2rem;
  height: 100%;

  // position: fixed;
  // top:6.5rem;

  background-color: $colour-white;
  z-index: 1;
  overflow: auto;

  width: 100%;

  right: 0;
  padding: 2rem;
  border-radius: 1rem;

  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.08);
}

.nav-item {
  padding: 1.6rem;
}

.nav-item:hover {
  background-color: rgba(232, 233, 233, 0.55);
  border-radius: 4px;
}

.close-org-dash {
  display: none;
  align-items: center;

  background-color: #fff;

  width: 270px;
  padding: 2rem 2.5rem;
  border: 1px solid rgba(164, 164, 164, 0.327);

  p {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .close-org-dash {
    display: flex;
  }

  .cart-text-res {
    display: none;
  }

  .auth-cart {
    display: flex;
  }

  .navbar-mid-item {
    display: none;
  }

  .navbar-right-item {
    justify-content: flex-end;
  }

  .navbar-responsive {
    grid-template-columns: auto auto auto;
  }

  .hamburger-button {
    display: flex;
  }
}
