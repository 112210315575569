.learningCard {
  overflow: hidden;
  /* Ensures the border-radius applies correctly */
  // max-width: 250px;
  width: 171px;
  height: 139px;

  /* Adjust width as needed */
  position: relative;
  flex-grow: 0;
  border-radius: 4.6px;
  border: solid 1px #ecf0f2;
  background-color: #fff;
  // height: 180px;
}

.learningCard-image {
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.learningCard-text {
  padding: 10px;
  margin: 0;
  //   font-size: 12px;
}

.learningCard::after {
  // content: '';
  // display: block;
  // height: 4px;
  // background: linear-gradient(to right, rgb(74, 78, 104), rgb(222, 222, 230));
  // border-radius: 0 0 10px 10px;
}

.learningCard-text {
  position: absolute;
  // width: 77px;
  // height: 18px;
  top: 10px;
  right: 10px;
  border-radius: 2px;
  background-color: #fff;
  padding: 2px 6px 1px;
  text-align: left;
}

.learningCard-tag {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #08415c;
}

.learningCard-tag ::first-letter {
  text-transform: capitalize;
}

.learningCard-title {
  // margin: 0;
  font-size: 10px;
  // margin: 10px 54px 10px 9px;
  font-size: 12px;
  font-weight: 900;
  // text-align: left;
  color: #08415c;
  margin-top: 10px;
  padding-left: 8px;

  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
}

.ongoingLearning-con {
  height: 100%;
  width: 100%;
  // background: #f2f2f2;
  //   border: 1px solid #e6e6e6;
  //   background: $info;
  color: #f2f2f2;
  padding: 16px;
  //   border-radius: 8px;
  margin: 0 30px 0 0;
  //   padding: 11px 35px 1px 16;
  border-radius: 6px;
  border: solid 1px #ecf0f2;
  background-color: #fff;
}

.ongoingLearning-progressBar {
  width: 100%;
  height: 3px;
  // margin-bottom: -5px;

  appearance: none;

  &::-webkit-progress-value,
  &::-moz-progress-bar {
    background-color: #08415c;
  }

  &::-webkit-progress-bar {
    background-color: #d2eaf5;
  }
}
