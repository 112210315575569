.od-menu,
.od-drop-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 9999999;
}

.od-menu-item {
  display: inline-block;
  //   background-color: #4285f4;
  position: relative;
}

.od-menu-item a {
  text-decoration: none;
  padding: 6px 10px;
  color: #fff;
  display: block;
}

.od-drop-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  //   min-width: 100%;
  width: 229px;
  height: 310px;

  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
}

.od-drop-menu-item {
  width: 100%;
}

.od-course-text {
  flex-grow: 0;
  font-size: 16px;
  line-height: 1.43;
  letter-spacing: 0.28px;
  text-align: left;
  color: #101010;
}

.od-dd-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  //   gap: 24px;
  margin: 0 30px 0 0;
  //   padding: 0;
}

.od-drop-menu-item:hover {
  background-color: #eee;
}

.od-drop-menu-item a {
  color: #555;
}

.od-menu-item:hover .od-drop-menu {
  display: block;
}

.od-createEvent-btn {
  width: 229px;
  height: 54px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin: 23px 0 0;
  padding: 15px 30px;
  border-radius: 6px;
  background-color: #08415c;
}

.od-create-event-text {
  flex-grow: 0;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  text-align: center;
  color: #fff;
}
