@import "../../utility/utility.styles.scss";

.feedback-container {
  textarea {
    height: auto;
  }

  h4 {
    font-family: Avenir;
    font-weight: normal;
    color: #08415c;
    margin-bottom: 16px;
  }
}
