.od-eventChart-welcome {
  height: 100%;
  width: 100%;
  //   background: $info;
  color: #fff;
  padding: 20px;
  border-radius: 4.6px;
  border: solid 1px #ecf0f2;
  background-color: #fff;
  @media screen and (max-width: 350px) {
    padding: 12px;
  }
}
