// cc is confemy credit
.cc-grid-table {
  grid-template-columns: 1fr 1fr 1fr auto auto !important;
  border: none !important;
  box-shadow: none !important;
  background-color: #fff !important;
  li {
    &:nth-child(-n + 5) {
      background-color: #eef1f5 !important;
    }
  }
}
