@import "../../../utility/utility.styles.scss";

// ust is user speaker table

.ust-table {
  tr {
    border-top: 1px solid $shade-1;
  }
  td {
    vertical-align: middle;
  }
}

// us is user speaker
.us-gridtable {
  grid-template-columns:
    auto minmax(128px, 1.2fr) minmax(160px, 1.5fr) minmax(100px, 1fr)
    auto auto !important;
}

// st is speaker table
.st-image-wrap {
  width: 52px;
  height: 52px;
  // border: 0.1px solid $shade-1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colour-white;
  overflow: hidden;

  &:hover {
    background-color: $background;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
