.savedVideo-outerDiv {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 70px);
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: var(--gray);
}

.savedVideo-innerDiv {
  width: 80%;
  height: auto;
}

.savedVideo-ttl {
  font-size: 2.5rem;
  font-weight: 700;
}

.savedVideo-boxs {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
}

.savedVideo-box1 {
  width: 73%;
}

.courses {
  /* margin: 0.5rem 0; */
}

.savedVideo-cnt,
.totalTxt {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  font-weight: 500;
}

.savedVideo-box2 {
  width: 25%;
}

.savedVideo-currrency {
  font-size: 2rem;
  font-weight: 700;
}

.savedVideo-totalDiscount,
.savedVideo-ttlDisPer {
  color: var(--light-gray);
  font-size: 1rem;
  font-weight: 400;
}

.savedVideo-totalDiscount {
  text-decoration: line-through;
}

.savedVideo-ctxt {
  font-size: 1rem;
  font-weight: 700;
}

.savedVideo-cpnBox {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.savedVideo-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin: 0 1rem;
}

.savedVideo-cpnCode {
  font-size: 1rem;
}

.savedVideo-emptyBdy {
  width: 100%;
  height: auto;
}

.savedVideo-cartItemsLen {
  margin: 1rem 0;
  display: flex;
  gap: 0.5rem;
  font-weight: 700;
}

.savedVideo-cartBox {
  width: 100%;
  min-height: 300px;
  height: auto;
  padding: 1rem;
  border-radius: 5px;
  border: 2px solid var(--gray100);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.savedVideo-emptyCartImg {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.savedVideo-whitlistedCourses {
  margin: 1rem 0;
}

.savedVideo-whTtl {
  font-size: 1rem;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .savedVideo-innerDiv {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .savedVideo-ttl {
    font-size: 2rem;
  }

  .savedVideo-boxs {
    display: flex;
    flex-direction: column-reverse;
  }

  .savedVideo-box1 {
    margin: 1rem 0;
    width: 100%;
  }

  .savedVideo-box2 {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .savedVideo-outerDiv {
    padding: 1rem;
  }
}
