// cdab is conf details action buttons
.cdab-grid {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-template-rows: auto;
  gap: 16px;
  margin: 0px auto 60px;
  @media screen and (max-width: 600px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
