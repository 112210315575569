.checkout-grid-wrap {
  padding: 0 20px;
}
.checkout-grid {
  display: grid;
  width: 900px;
  max-width: 100%;
  height: auto;
  margin: 40px auto;
  padding: 40px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  gap: 20px;
  border: 2px solid #ced9de;
  border-radius: 8px;

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 32px;
  }
  @media screen and (max-width: 450px) {
    padding: 24px 12px;
  }
}

.checkout-total-wrap {
  padding: 16px;
  @media screen and (max-width: 850px) {
    border: 1px solid #ced9de;
  }
}
