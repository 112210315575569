@import './utility.styles.scss';

// body styles

body {
  font-family: Avenir, sans-serif;
}

// Heading styles

.huge-title {
  font-family: Avenir;
  font-size: 50px;
  font-weight: 900;
  line-height: 1;
  color: $primary;
}

// h1 = Page-Title in zeplin project

h1 {
  font-family: Avenir-Heavy;
  font-size: 40px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.25px;
  color: $primary;
  @media screen and (max-width: 600px) {
    font-size: 2.8vh;
  }
}

// h2 = Section-title-1 in zeplin project

h2 {
  font-family: Avenir-Heavy;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  color: $confemy-black;
  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
}

// h3 = Heading in zeplin project

h3 {
  font-family: Avenir-Heavy;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  font-size: 24px;
  font-weight: 900;
}

// h4 = Subheading in zeplin project

h4 {
  font-family: Avenir-Heavy;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
}

.avenir-heavy-18 {
  font-family: Avenir-Heavy;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: $gray-3;
}

.avenir-heavy-16 {
  @include avenir-heavy-16;
}

.avenir-20-900 {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $confemy-black;
}

.avenir-20-500 {
  @include avenir-500;
  font-size: 2rem;
}

.avenir-18-500 {
  @include avenir-500;
  font-size: 1.8rem;
}

.avenir-16-500 {
  @include avenir-500;
  font-size: 1.6rem;
}

.body-bold {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: $gray-3;
}

.body-regular-gray3 {
  @include body-regular-gray3;
}

.body-regular-a-gray3 {
  @include body-regular-a-gray3;
}

.caption-1-regular-gray3 {
  @include caption-1-regular;
  color: $gray-3;
}

.caption-1-regular-gray2 {
  @include caption-1-regular;
  color: $gray-2;
}

.caption-1-regular-cblack {
  @include caption-1-regular;
  color: $confemy-black;
}

.caption-1-medium-primary {
  @include caption-1-medium;
  color: $primary;
}

.caption-1-medium-cblack {
  @include caption-1-medium;
  color: $confemy-black;
}

.caption-1-heavy-primary {
  @include caption-1-heavy;
  color: $primary;
}

.caption-1-heavy-cblack {
  @include caption-1-heavy;
  color: $confemy-black;
}

.caption-1-heavy-gray3 {
  @include caption-1-heavy;
  color: $gray-3;
}

.caption-1-heavy-gray2 {
  @include caption-1-heavy;
  color: $gray-2;
}

.caption-2-bold-cblack {
  @include caption-2-bold;
  color: $confemy-black;
}

.caption-2-bold-gray3 {
  @include caption-2-bold;
  color: $gray-3;
}

.caption-2-regular-gray3 {
  @include caption-2-regular;
  color: $gray-3;
}

.caption-2-regular-primary {
  @include caption-2-regular;
  color: $primary;
}

.caption-3 {
  font-family: Avenir-Roman;
  font-size: 12px;
}

.section-title-1 {
  @include Section-title-1;
}

// Some other font styles

// avenir-roman-18-used for footer links

.avenir-roman-18 {
  @include avenir-roman-18;
}

.avenir-roman-18-gray3 {
  font-family: Avenir-Roman;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4rem;
  letter-spacing: 0.2px;
  color: $gray-3;
}

.editor-text {
  font-family: Avenir-Roman;
  // font-size: large;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: $gray-3;
}

.media-tag-text {
  font-family: Avenir;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #08415c;
}

.conference-course-tag-text {
  font-family: Avenir;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #08415c;
}
