.popularEvent-con {
  height: 100%;
  width: 100%;
  // background: #f2f2f2;
  //   border: 1px solid #e6e6e6;
  //   background: $info;
  color: #f2f2f2;
  padding: 16px;
  //   margin-bottom: 20px;
  //   border-radius: 8px;
  //   margin: 0 30px 0 0;
  //   padding: 11px 35px 1px 16;
  border-radius: 6px;
  border: solid 1px #ecf0f2;
  background-color: #fff;
}
