@import "../../utility/utility.styles.scss";

.custom-datepicker-wrap {
  .react-datepicker__input-container {
    input {
      width: 100%;
      display: block;
      padding: 1rem 1rem 1rem 1.6rem;
      font-family: "Avenir-Roman";
      font-size: 1.6rem;
      outline: none;
      border-radius: 4px;
      border: solid 2px $shade-1;
      background-color: $colour-white;
      @include caption-1-regular;
      color: $confemy-black;
      transition: 0.1s ease-out;
      &:hover {
        border: solid 2px $shade-4;
      }
      &:focus {
        border: 2px solid $info;
      }
    }
  }

  .react-datepicker__close-icon {
    right: 8px;
    &::after {
      background-color: $colour-white;
      color: #cccccc;
      font-size: 2.4rem;
    }
  }
}

.react-datepicker__month-container {
  font-size: 1.4rem;
  font-family: "Avenir-Roman";

  .react-datepicker__header {
    padding: 14px 8px;
    font-size: 1.4rem;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 1.4rem;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    top: 3px;
  }

  .react-datepicker__month {
    padding: 1.2rem;
  }

  .react-datepicker__month-text {
    display: inline-block;
    width: 5rem;
    margin: 0.5rem;
    font-size: 1rem;
    padding: 0.2rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 2.5rem;
  }
}
