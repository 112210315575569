@import "../../utility//utility.styles.scss";

.signin-social {
  width: 100%;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 2px $shade-1;
  margin-bottom: 4rem;
  &:active {
    background-color: $shade-2;
  }
}

.signin-google-icon {
  width: 26px;
  height: 26px;
  margin: 0 13px 0 0;
}
