@import "../../utility/utility.styles.scss";

.speakercard {
  position: relative;
  height: 290px;
  width: 100%;
  max-width: 322px;
  max-height: 290px;
  // height: 304px;
  // width: 392px;
  // max-width: 392px;
  // max-height: 304px;
  border: 1px solid $shade-1;
  border-radius: 4px;
  background-color: $colour-white;
  overflow: hidden;
  // padding: 0 60px 16px;
  padding: 0 24px 16px;

  &:hover {
    & > .speakercard-overlay {
      opacity: 0.4;
      transition: all 300ms ease;
    }

    & > .speakercard-delete-circle {
      opacity: 1;
      transition: opacity 300ms ease;
    }
  }
}

.speakercard-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 300ms ease;
  border-radius: 4px;
  background-color: $confemy-black;
}

.speakercard-delete-circle {
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colour-white;
  border-radius: 50%;

  position: absolute;
  transition: 300ms ease;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.speaker-image-container {
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
}

.speaker-image-wrap {
  width: 160px;
  max-width: 1600%;
  // height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background;
  overflow: hidden;
}
.speaker-profilesketch-size {
  width: 80px;
  height: 80px;
}

.speaker-name-container {
  height: 30%;
  width: 100%;
  text-align: center;
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    cursor: all-scroll;
  }
}

.speaker-name {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.2px;
}

// Cd = conf details; CdSpeakercard styles

.cd-speakercard {
  position: relative;
  width: 100%;
  // width: 322px;
  height: 290px;
  max-width: 322px;
  max-height: 290px;
  overflow: hidden;
  padding: 0 60px 16px;
  background-color: $colour-white;
  border: 1px solid $shade-1;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

  &:hover {
    background-color: $shade-1;
    // border: 1px solid $shade-1;
    // border-radius: 4px;
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  }

  @media screen and (max-width: 354px) {
    padding: 0 54px 16px;
  }
}

.cd-speaker-image-wrap {
  width: 160px;
  height: 160px;
  border: 0.1px solid $shade-1;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colour-white;
  overflow: hidden;
  &:hover {
    background-color: $background;
  }
}
