.abd-grid {
  width: 100%;
  padding: 8px 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 42px auto;
  column-gap: 24px;
  row-gap: 4px;
  align-items: center;

  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    row-gap: 8px;
  }
}
