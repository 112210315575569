// od is organizer dashboard
.od-content-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  min-height: 320px;
  gap: 16px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
}

.ad-content-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  min-height: 220px;
  gap: 16px;
  @media screen and (max-width: 1240px) {
    display: block;
  }
}

.ad-content-wrapper-chart {
  grid-row: 1/3;
}

.od-content-wrapper2 {
  width: 100%;
  display: grid;
  gap: 16px;
  // grid-template-rows: 1fr 1fr;
  grid-auto-rows: auto;
  @media screen and (max-width: 1200px) {
    display: block;
  }
}

.od-namecard-grid {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 16px;
  @media screen and (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ad-namecard-grid {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  gap: 16px;
  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.od-section3-grid {
  width: 100%;
  height: 416px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 16px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}
