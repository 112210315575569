@import '../../utility/utility.styles.scss';

.confSwiper {
  max-width: 1360px;
  padding: 50px;
  @media screen and (max-width: 680px) {
    padding: 32px;
  }
  @media screen and (max-width: 350px) {
    padding: 20px;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
}
