@import '../../utility//utility.styles.scss';
// at  = attendee ticket

.at-container {
  padding: 0px 16px 24px;
  @media screen and (max-width: 900px) {
    padding: 0px 0px 8px;
  }
}

.at-wrap {
  width: 100%;
  border: 1px solid $shade-1;
  // margin: 0px 24px 32px;
  border-radius: 4px;
  padding: 24px;
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-template-rows: auto;
  gap: 24px;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 120px 1fr;
    column-gap: 16px;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 100px 1fr;
    column-gap: 16px;
    padding: 12px;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    column-gap: 16px;
  }
}

.conf-course-tag {
  width: 142px;
  height: 30px;
  padding: 0px 4px 0 6px;
  display: flex;
  align-items: center;
  background-color: #dceef8;
}
// .at-body {
//   width: 100%;
//   display: grid;
//   grid-template-columns: 2fr 1fr;
//   grid-template-rows: auto;
//   gap: 24px;
// }

.at-content {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  gap: 40px;

  p {
    @include caption-1-regular;
    color: #757575;
    font-size: 14px;
  }
  h4 {
    font-size: 14px;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.at-column-grid {
  // grid-column: 1/2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 6px;
}

.at-column2 {
  // grid-column: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.barcode-container {
  display: inline-block;
  width: fit-content;
  transform-origin: 52% 100%;
  transform: rotate(-90deg);

  @media screen and (max-width: 700px) {
    transform-origin: center;
    transform: rotate(-360deg);
  }
}
