@import '../../utility//utility.styles.scss';

#searchtickets {
  padding-left: 5.2rem;
}

.ticker-form {
  @media screen and (max-width: 432px) {
    margin-bottom: 30px;
  }
}
