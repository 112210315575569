// conference banner
// The banner aspect ratio is 16:9 ie 1.7777
// 1920 x 1080 pixels, its standard HD ratio for HD TVs, presentations, and social media cover photos.
// 1280 x 720 pixels, its standard HD format in photography and film.

//  si is single image
.si-uploader-container {
  position: relative;
  // width: 817.77px;
  // height: 460px;
  background-color: $colour-white;

  &:hover {
    & > .si-uploader-overlay {
      opacity: 0.4;
      transition: all 300ms ease;
    }

    // & > .si-uploader-wrap {
    //   padding: 8px;
    //   transition: padding 300ms ease;
    //   background-color: $shade-2;
    // }

    & > .si-uploader-delete-circle {
      opacity: 1;
      transition: opacity 300ms ease;
    }
  }
}

.si-uploader-wrap {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  padding: 0px;
  transition: padding 400ms ease;
}

.si-uploader-delete-circle {
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colour-white;
  border-radius: 50%;

  position: absolute;
  transition: 300ms ease;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.si-uploader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 300ms ease;
  border-radius: 4px;
  background-color: $confemy-black;
}

.uploaded-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 300ms ease;
  backface-visibility: hidden;
}

.od-banner-container {
  // height: 37.125rem;
  width: auto;
  height: auto;
  max-width: 270px;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 10px;
  aspect-ratio: 16/9;
  @media screen and (max-width: 600px) {
    margin-bottom: 14px;
  }
}
