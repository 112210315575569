@import "../../utility/utility.styles.scss";

.location-dropdown {
  position: absolute;
  width: 25rem;
  height: auto;
  min-height: 230px;
  // max-height: 100vh;
  z-index: 1;
  // overflow-y: scroll;
  top: 6.4rem;
  right: 0;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.08);
  background-color: $colour-white;
}

.location-item {
  padding: 1.2rem 1.6rem 1.2rem 2.4rem;
  border-radius: 4px;
  // box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.08);
  background-color: $colour-white;

  &:hover {
    background-color: $shade-2;
    cursor: pointer;
  }
}

.ld-topcities {
  padding: 2.1rem 1.6rem 1.3rem 2.4rem;
  background-color: $colour-white;
  @include avenir-heavy-18;
  color: $confemy-black;
  border-top: 2px solid $shade-1;
  cursor: default;
}

.ld-searchbox {
  padding: 0.8rem;
  input[type="text"] {
    padding-left: 1.6rem;
  }
}

.ld-citieswrap {
  overflow: hidden;
  min-height: 5.8rem;
  max-height: calc(100vh - 34.2rem);
  overflow-y: scroll;
}
