@import "../../utility//utility.styles.scss";

// bdfo = booking details for organization

.bdfo-booking-table-wrap {
  width: 100% !important;
  overflow-x: scroll;
}
.bdfo-booking-table-sort {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-bottom: 32px;
  gap: 8px;
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
.bdfo-booking-table {
  width: 100%;
  border-collapse: collapse;
  @include avenir-heavy-18;
  color: $gray-3;
  font-size: 16px;
  thead {
    tr {
      background-color: $shade-1;
      border: 1px solid $shade-1;
      th {
        padding: 16px;
        text-align: left;
      }
    }
  }
  tbody {
    vertical-align: top;
    td {
      padding: 16px;
      text-align: left;
      color: $gray-2;
    }
  }
}

.bdfo-booking-gridtable {
  grid-template-columns:
    minmax(120px, 1fr) auto auto auto
    auto auto auto minmax(120px, 1fr) auto !important;
  font-size: 15px !important;

  li {
    &:nth-child(9n + 9) {
      overflow: visible;
    }
  }
  // selecting the first column

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}
