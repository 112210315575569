.attendee-event-welcome {
  height: 200px;
  width: 100%;

  // background: #f2f2f2;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  background-color: #ecf0f2;
  //   background: $info;
  margin: 0 30px 0 0;
  padding: 42px 230px 30px 36px;
  border-radius: 6px;

  @media screen and (max-width: 580px) {
    padding: 12px;
    margin: 0;
  }
}
