@import "../../utility/utility.styles.scss";

.add-documents-wrap {
  width: 100%;
  padding: 0px 40px;

  @media screen and (max-width: 600px) {
    padding: 0px 8px;
  }
}

.ucr-gridtable {
  width: max(280px, 100%);
  min-height: 72px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  @include avenir-heavy-16;
  font-size: 14px;
  color: $gray-2;
  border: 1px solid #ced9de;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  align-items: center;

  li {
    list-style: none;
    height: 100%;
    padding: 1rem 1.2rem;
    // selecting the first row
    &:nth-child(-n + 3) {
      background-color: $background;
      color: $gray-3;
    }
    // selecting the last column
    &:nth-child(3n + 3) {
      text-align: right;
    }
    // selecting every item after first header row
    &:nth-child(n + 4) {
      overflow: hidden;
      overflow-wrap: break-word;
    }
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.gc-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 16px;
}
