.list-conf-section {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 5%;
  padding-right: 5%;
}
.list-conf-grid {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 40px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.list-conf-sketch {
  max-width: 100%;
  @media screen and (max-width: 700px) {
    padding: 60px;
  }
  @media screen and (max-width: 600px) {
    padding: 40px;
  }
  @media screen and (max-width: 500px) {
    padding: 32px;
  }
  @media screen and (max-width: 400px) {
    padding: 20px;
  }
}

.easy-setup-section {
  padding-top: 154px;
  padding-bottom: 154px;
  padding-left: 15%;
  padding-right: 15%;

  @media screen and (max-width: 700px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.easy-setup-svg-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 700px) {
    justify-content: center;
  }
}

.smart-ticketing-svg-wrap {
  display: flex;
  align-items: center;
  @media screen and (max-width: 700px) {
    justify-content: center;
  }
}

.easy-setup-text-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 700px) {
    justify-content: center;
    text-align: center;
  }
}

.easy-setup-text {
  margin: auto 0;
  @media screen and (max-width: 700px) {
    text-align: center;
  }
}
.easy-setup-svg {
  max-width: 100%;
  width: 295px;
  height: 221px;
}

.smart-ticketing-svg {
  max-width: 100%;
  width: 270px;
  height: 181px;
}
