.upcoming-conf-img-wrap {
  width: auto;
  height: auto;
  aspect-ratio: 18/8;
  max-width: 282px;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
}

.upcoming-banner-container {
  // width: 100%;

  @media screen and (max-width: 600px) {
    width: 100%;
    grid-column: 1/-1;
  }
  @media screen and (max-width: 450px) {
    // display: flex;
    // justify-content: center;
  }
}

.upcomingConfs-title {
  cursor: pointer;
  font-size: 20px;
  @media screen and (max-width: 900px) {
    font-size: 8px;
  }
  @media screen and (max-width: 732px) {
    font-size: 8px;
  }
  @media screen and (max-width: 600px) {
    font-size: 8px;
  }
}

.upcomingConfs-card {
  width: 100%;
  display: grid;
  //   grid-template-columns: 282px 1fr auto;
  grid-template-columns: 200px 200px;
  grid-template-rows: auto;
  //   background-color: $shade-2;
  border-radius: 0.5rem;
  border: 1px solid #e6e6e6;
  padding: 0.7rem;
  gap: 2.4rem;
  @media screen and (max-width: 732px) {
    grid-template-columns: 2.2fr 3.8fr auto;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr auto;
    padding: 1rem;
  }
}

.courseSwiper {
  max-width: 550px;
  // padding: 10px;
  // padding: 50px;
  // @media screen and (max-width: 680px) {
  //   padding: 32px;
  // }
  // @media screen and (max-width: 350px) {
  //   padding: 20px;
  // }
  .swiper-slide {
    flex-shrink: unset;
  }

  .swiper {
    width: 100%;

    height: 100%;
  }

  @media screen and (max-width: 830px) {
    max-width: 450px;
  }
  @media screen and (max-width: 530px) {
    max-width: 200px;
  }
}

.upcomingConf-card {
  height: 92%;
  width: 100%;
  // background: #f2f2f2;
  border: 1px solid #ecf0f2;
  //   background: $info;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;

  @media screen and (max-width: 1240px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 350px) {
    padding: 12px;
  }
}
