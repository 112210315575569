@import "../../utility/utility.styles.scss";

.footer {
  height: 18.1rem;
  width: 100%;
  max-width: $containerWidth;
  margin: 0 auto;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      display: flex;
      flex-direction: column;
      list-style: none;
      white-space: nowrap;
      margin-right: 8px;
      margin-left: 8px;
    }
    a {
      text-decoration: none;
      color: $colour-white;
    }
  }
  @media screen and (max-width: 700px) {
    height: auto;
    grid-template-columns: 1fr;

    ul {
      flex-direction: column;
      li {
        text-align: center;
        margin: 1rem 0;
      }
    }
    & > div {
      justify-self: center;
      margin-bottom: 24px;
      margin-top: 24px;
    }
  }
}

.footer-confemy-text {
  font-family: Avenir-Heavy;
  font-size: 26px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: $colour-white;
}

.social-whitecircle-icon {
  width: 3.2rem;
  height: 3.2rem;
}
.social-white-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  width: 3.2rem;
  height: 3.2rem;
}
