.submit-cancel-loader-wrap {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    min-height: 52px;
    min-width: 200px;
    @media screen and (max-width: 700px) {
      min-width: 112px;
    }
    // @media screen and (max-width: 350px) {
    //   min-width: 96px;
    // }
  }
}

.fullwidth-submit-cancel-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  & > div {
    width: 100%;
  }
  button {
    min-height: 52px;
    width: 100%;
    padding: 1.6rem;
  }
}

.submit-loader-wrap {
  display: inline-block;
  position: relative;

  button {
    min-height: 52px;
    min-width: 212px;
    // @media screen and (max-width: 750px) {
    //   // width: 120px;
    //   min-width: auto;
    // }
  }
}

.schedule-submit-loader-wrap {
  display: inline-block;
  position: relative;

  button {
    min-height: 45px;
    // min-width: 140px;
    width: 155px;
    // @media screen and (max-width: 750px) {
    //   // width: 120px;
    //   min-width: auto;
    // }
  }
}

.fullwidth-submit-loader {
  display: block;
  width: 100%;
  position: relative;
  button {
    min-height: 52px;
    width: 100%;
  }
}
