@import '../../utility/utility.styles.scss';
// Alerts

.local-alert-container {
  width: 100%;
  margin: 12px auto;
}
.local-alert {
  width: 100%;
  padding: 0.8rem 2rem;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include caption-2-regular;

  &.local-alert-primary {
    background-color: $primary;
    color: $colour-white;
  }
  &.local-alert-dark {
    // background-color: $shade-1;
    background-color: #ffce32;
    color: $primary;
  }
  &.local-alert-success {
    background-color: $accent;
    color: $colour-white;
  }
  &.local-alert-danger {
    background-color: $warning;
    color: $colour-white;
  }
  &.local-alert-info {
    background-color: $info;
    color: $colour-white;
  }
}
