@import "../../utility/utility.styles.scss";

// sbt = sales by tickets
.sbt-wrap {
  margin-bottom: 60px;
  @media screen and (max-width: 700px) {
    margin-bottom: 40px;
  }
}

.sbt-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  // word-wrap: break-word;
  word-wrap: normal;
  @include avenir-heavy-18;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
  thead {
    tr {
      background-color: $shade-1;
      th {
        padding: 16px;
        text-align: left;
        @media screen and (max-width: 600px) {
          padding: 8px;
        }
      }
    }
  }
  tbody {
    tr {
      background-color: $shade-2;
      td {
        padding: 16px;
        text-align: left;
        color: $gray-2;
        @media screen and (max-width: 600px) {
          padding: 8px;
        }
      }
      // every odd row is backcolor white
      &:nth-child(odd) {
        background-color: #fff;
      }
    }
  }
}
