.courseCardOptions-outerDiv {
  display: block;
  width: 230px;
  height: 250px;

  /* margin: 0.5rem; */
  color: var(--gray);
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.courseCardOptions-optionsBox {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  background-color: var(--gray86);
}

.courseCardOptions-menuBtn {
  // width: 35px;
  // height: 35px;
  width: 25px;
  height: 25px;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.courseCardOptions-menuIcon {
  width: 20px;
  height: 20px;
}

.courseCardOptions-menuBox {
  position: absolute;
  top: 40px;
  right: 0;
  width: 250px;
  height: auto;
  background-color: var(--white);
  border: 1px solid var(--gray86);
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
}

.courseCardOptions-optionComp {
  padding: 0.5rem;
  border-bottom: 1px solid var(--gray86);

  &:last-child {
    border-bottom: none;
  }
}

.courseCardOptions-imgBox {
  width: 100%;
  height: 50%;
  position: relative;
}

.courseCardOptions-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courseCardOptions-imgBox:hover .hovImgBox {
  display: flex;
}

.courseCardOptions-hovImgBox {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.courseCardOptions-hovImg {
  width: 30px;
  height: 30px;
  filter: invert(1);
}

.courseCardOptions-bdy {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.courseCardOptions-ttl {
  font-size: 1.6rem;

  /* width: 50%; */
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.courseCardOptions-author {
  width: 100%;
  font-size: 1.28rem;
  color: var(--light-gray);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.courseCardOptions-progressBar {
  width: 100%;
  height: 2px;
  appearance: none;

  &::-webkit-progress-value,
  &::-moz-progress-bar {
    background-color: var(--indigo);
    // background-color: #08415c;
  }

  &::-webkit-progress-bar {
    background-color: var(--gray86);
    // background-color: #d2eaf5;
  }
}

.courseCardOptions-footerBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.28rem;
}

.txt {
}

.courseCardOptions-starsRatings {
  display: flex;
}

.courseCardOptions-mHeader {
  text-align: center;
  font-size: 2.4rem;
}

.courseCardOptions-mtxt {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0;
}

.courseCardOptions-stars {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.courseCardOptions-star {
  margin: 0 1rem;
  cursor: pointer;
}
