@import './utility.styles.scss';

.table-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
}

.table {
  width: 100%;
  border-collapse: collapse;
  @include avenir-heavy-16;
  color: $gray-3;
  border: 1px solid #ced9de;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }

  td {
    padding: 1rem 1.6rem;
    text-align: left;
    color: $gray-2;
  }
  th {
    padding: 1rem 1.6rem;
    text-align: left;
    &:last-child {
      text-align: right;
    }
  }

  thead {
    tr {
      background-color: $shade-1;
      border: 1px solid $shade-1;
      border-radius: 4px;
    }
  }
  tbody {
    vertical-align: top;
  }
}

.table-5col-td-right {
  td {
    &:nth-child(5n + 5) {
      text-align: right;
    }
  }
}

.table-3col-td-right {
  td {
    &:nth-child(3n + 3) {
      text-align: right;
    }
  }
}

.table-7col-td-right {
  td {
    &:nth-child(7n + 7) {
      text-align: right;
    }
  }
}

.grid-table-wrap {
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
}

.grid-table-border-wrap {
  width: 100%;
  height: 600px;
  overflow: auto;
  border: 1px solid #ced9de;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
}

// .grid-table {
//   width: 100%;
//   margin-bottom: 24px;
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   @include avenir-heavy-16;
//   color: $gray-2;
//   border: 1px solid #ced9de;
//   border-radius: 4px;
//   box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
//   overflow: hidden;
//   word-break: break-word;
//   align-items: center;

//   li {
//     list-style: none;
//     padding: 1.2rem 1.6rem;

//     // selecting the first row
//     &:nth-child(-n + 6) {
//       background-color: $shade-1;
//       color: $gray-3;
//     }
//     // selecting the first column
//     &:nth-child(6n + 6) {
//       text-align: right;
//     }
//   }

//   @media screen and (max-width: 600px) {
//     font-size: 14px;
//   }
// }

$colnum: (2, 3, 4, 5, 6, 7, 8, 9, 10);
$pxamt: (
  300,
  350,
  400,
  450,
  500,
  550,
  600,
  650,
  700,
  750,
  800,
  850,
  900,
  950,
  1000
);

@each $num in $colnum {
  @each $px in $pxamt {
    .grid-table-#{$num}-#{$px} {
      width: max(#{$px}px, 100%);
      margin-bottom: 24px;
      display: grid;
      grid-template-columns: repeat(#{$num}, 1fr);
      @include avenir-heavy-16;
      // @include caption-1-medium;
      color: $gray-2;
      border: 1px solid #ced9de;
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
      align-items: center;
      // background: #fff;

      li {
        list-style: none;
        height: 100%;
        padding: 1.2rem 1.6rem;

        // selecting the first row
        &:nth-child(-n + #{$num}) {
          background-color: $shade-1;

          color: $gray-3;
          padding: 1.6rem 1.6rem;
        }
        // selecting the last column
        &:nth-child(#{$num}n + #{$num}) {
          text-align: right;
        }
        // selecting every item after first header row
        &:nth-child(n + #{$num + 1}) {
          overflow: hidden;
          overflow-wrap: break-word;
        }
      }
      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
}

.breakable-grid-item {
  overflow: hidden;
  overflow-wrap: break-word;
}

.scrollbar-grid-table-wrap {
  width: 100%;
  height: 600px;
  border: 1px solid #ced9de;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  background-color: #eef1f5;
  overflow: auto;
  // hide scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
