@import "../../utility/utility.styles.scss";
.my-orgs-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.my-orgs-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  margin-bottom: 40px;

  button {
    background-color: $shade-2;
  }
  @media screen and (max-width: 380px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    button {
      margin-bottom: 16px;
    }
  }
}
// height of the grid rows is controlled by logo container height
.my-orgs-grid {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 120px 1fr 60px;
  grid-template-rows: auto;
  gap: 12px;
  align-items: center;

  @media screen and (max-width: 600px) {
    grid-template-columns: 80px 1fr 40px;
    gap: 8px;
  }

  @media screen and (max-width: 380px) {
    grid-template-columns: 1fr 80px;
  }
}

.my-orgs-grid-item {
  padding: 8px;
  width: 100%;
  height: 100%;
  background-color: $shade-2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.org-logo-hide {
  @media screen and (max-width: 380px) {
    display: none;
  }
}

.org-name {
  font-family: Avenir-Heavy;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
}

.org-logo-container {
  width: 100%;
  max-width: 100%;
  height: 68px;
  overflow: hidden;
  background-color: $colour-white;
  border: 2px solid $shade-2;
  cursor: pointer;
  text-align: center;
  padding: 8px;

  img {
    width: auto;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 600px) {
    // grid-template-columns: 80px 1fr 40px;
    height: 40px;
    padding: 4px;
  }
}
.my-orgs-add-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
