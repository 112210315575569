@import '../../utility/utility.styles.scss';

.vm-container {
  width: 100%;
  // max-width: 144rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 40rem;
  grid-template-rows: auto;
  row-gap: 4rem;
  @media screen and (max-width: 960px) {
    // hide sidemenu container below this
    display: block;
  }
}

.vm-container-block {
  width: 100%;
  margin: 0 auto;
}

.vm-detail-container {
  background-color: $primary;
}
.vm-player-container {
  position: relative;
  padding-left: 75px;
  padding-right: 75px;
  background-color: $primary;
  width: 100%;
  height: auto;
  @media screen and (max-width: 600px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.vm-menu {
  @media screen and (max-width: 960px) {
    // hide sidemenu container below this
    display: none;
  }
  width: 100%;
  padding: 0.2rem 0;
}

.vm-customCheckBox-checkbox {
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid #2d2f31;
  appearance: none;
  outline: 2px solid #1c1d1f;
  accent-color: #1c1d1f;
  padding: 0.1rem;
  cursor: pointer;
  margin-right: 10px;

  &:checked {
    background-color: #1c1d1f;
    background-image: url('../../components/video-course/video-course-icons/white-check.png');
    background-repeat: no-repeat;
    background-size: 14px;
  }
}

.vm-section-title {
  font-size: 1.4rem;
  font-weight: 800;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vm-courseContent-subDurTxt {
  color: #6a6f73;
  font-size: 1.38rem;
}

.vm-courseContent-subDrp {
  position: relative;
}

.vm-courseContent-subDrpBox {
  padding: 0.4rem 0.4rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: 1px solid #6a6f73;
  &:hover {
    background-color: #c1c1c2;
  }
}

.vm-courseContent-subIcon {
  width: 15px;
  height: 15px;
}

.vm-courseContent-drowDownIcon {
  width: 10px;
  height: 10px;
  transition: all 0.5s;
}

.vm-courseContent-active {
  background-color: gray;
}

.vm-courseContent-reverseDrowDownIcon {
  transform: rotate(180deg);
}

.vm-courseContent-subDrpTxt {
  font-size: 1.44rem;
}

.vm-courseContent-subDrpItemsBox {
  position: absolute;
  top: 33px;
  right: 0;
  padding: 0.5rem;
  width: max-content;
  max-width: 265px;
  z-index: 1;
  border: 1px solid #d1d7dc;
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
}

.vm-courseContent-resItem {
  margin: 0.5rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
  color: #1c1d1f;

  &:hover {
    color: #a435f0;

    .courseContent-resItemIcon {
      filter: brightness(0) saturate(100%) invert(28%) sepia(93%)
        saturate(3440%) hue-rotate(264deg) brightness(95%) contrast(98%);
    }
  }
}

.vm-courseContent-resItemIcon {
  width: 15px;
  height: 15px;
}

.vm-courseContent-descBdy {
  padding: 0.6rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: #d1d7dc;
  }
}
.vm-courseContent-active {
  background-color: #d1d7dc;
}

.vm-dark-play-icon {
  width: 15px;
  height: 15px;
  color: #6a6f73;
}

.vm-leftArrow {
  position: absolute;
  top: 50%;
  left: 0%;
  // width: 25px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  border: 1px solid #ced9de;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    left: -0.6%;
    height: 10px;
    border: none;
  }
}

.vm-rightArrow {
  position: absolute;
  top: 50%;
  // width: 25px;
  height: 50px;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  border: 1px solid #ced9de;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    // margin-right: 7px;
    // width: 10px;
    height: 10px;
    border: none;
  }
}

.vm-content-title {
  border: 1px solid $shade-1;
  padding: 1.3rem 3.1rem;
  background-color: $shade-3;
  @media screen and (max-width: 600px) {
    padding: 1.4rem 1.4rem;
  }
}

.vm-title-wrapper {
  @media screen and (max-width: 600px) {
    padding: 5px 0 5px;
  }
}
.vm-title {
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.vm-content-hide-icon {
  margin-left: auto;
  justify-content: 'flex-end';
  padding-right: 25px;
  cursor: pointer;
  @media screen and (max-width: 960px) {
    display: none;
  }
}

.vm-arrowIcon {
  width: 100%;
  height: auto;
  @media screen and (max-width: 600px) {
    height: 20px;
  }
}
