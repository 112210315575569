.img-carousal {
  width: 660px;
  max-width: 100%;
  // height: 371.25px;
  aspect-ratio: 16/9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-wrapper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
