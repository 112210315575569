@import "../../utility//utility.styles.scss";

.payment-status-container {
  max-width: 100%;
  height: auto;
  padding: 20px;
}
.payment-status-box {
  width: 506px;
  max-width: 100%;
  min-height: 292px;
  margin: 40px auto;
  padding: 24px 40px 40px;
  border-radius: 16px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success-tick {
  color: #9abc66;
  font-size: 50px;
  line-height: 100px;
  //   font-size: 100px;
  //   line-height: 200px;
  //   margin-left: -15px;
}

.success-tick-wrap {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid $shade-1;
  display: flex;
  align-items: center;
  justify-content: center;
}
