@import "../../utility//utility.styles.scss";

.refund-details-wrap {
  padding: 0px 60px;
}

.refund-flex-vc {
  display: flex;
  align-items: center;

  &-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-se {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 528px) {
    display: block;
    &-sb {
      display: block;
    }
  }
}

.refun-preview-wrap {
  width: 80rem;
  max-width: 100%;
  margin: 0 auto;
}
// .price-flex-vc {
//   display: flex;

//   &-sb {
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//   }
//   &-se {
//     display: flex;
//     justify-content: space-evenly;
//     flex-wrap: wrap;
//   }
//   @media screen and (max-width: 528px) {
//     display: block;
//     &-sb {
//       display: block;
//     }
//   }
// }
