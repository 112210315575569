@import "../../utility//utility.styles.scss";

.social-display-grid {
  width: 100%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 140px 1fr auto;
  grid-template-rows: auto;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;

  & > div:nth-child(2) {
    max-width: 300px;
    justify-self: end;
  }

  & > div:last-child {
    justify-self: end;
  }
  p {
    font-family: Avenir-Heavy;
  }

  @media screen and (max-width: 520px) {
    grid-template-columns: 1fr;
    & > div:nth-child(2) {
      max-width: 300px;
      justify-self: start;
    }
    & > div:last-child {
      justify-self: start;
    }
  }
}

.social-delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: $shade-1;
  }
}
