@import '../../utility/utility.styles.scss';

.od-welcome {
  height: 100%;
  width: 100%;
  background: #448afc;
  //   background: $info;
  color: #fff;
  padding: 24px;
  border-radius: 8px;
  @media screen and (max-width: 350px) {
    padding: 12px;
  }
}

.ad-welcome {
  height: 100%;
  width: 100%;
  // background: #f2f2f2;
  border: solid 1px #ecf0f2;
  //   background: $info;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  @media screen and (max-width: 350px) {
    padding: 12px;
  }
}

.ad-event-welcome {
  height: 200px;
  width: 100%;

  // background: #f2f2f2;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  background-color: #dceef8;
  //   background: $info;
  margin: 0 30px 0 0;
  padding: 42px 230px 30px 36px;
  border-radius: 6px;

  @media screen and (max-width: 570px) {
    padding: 12px;
  }
}
.od-welcome-buttons {
  padding: 0 8px 0 0;
  cursor: pointer;
  border: none;
  background-color: #448afc;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  &:hover {
    color: #111827;
  }
}

.welcome-barchart-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 100%;
  width: 100%;
  box-sizing: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: 2px solid #ced9de;
  border-radius: 8px;

  @media screen and (max-width: 750px) {
    justify-content: left;
  }

  @media screen and (max-width: 350px) {
    padding: 0px;
  }
}

.welcome-logo-bg {
  padding: 4px 12px;
  border-radius: 4px;
  width: fit-content;
  background-color: #fff;
  margin-bottom: 12;
}
