.course-btn {
  width: max-content;
  margin: 0.3rem;
  padding: 0.4rem;
  border: 1px solid #ced9de;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.course-txtBox {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.course-txt {
  margin: 0 0.3rem;
}

.course-btnImg {
  width: 80%;
  height: 90%;
}
