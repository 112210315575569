@import "../../utility/utility.styles.scss";

// .cd-grid {
//   width: 100%;
//   display: grid;
//   grid-template-columns: 66rem 40.8rem;
//   grid-template-rows: auto;
//   column-gap: 4.6rem;
//   position: relative;

//   @media screen and (max-width: 1146px) {
//     column-gap: 1.6rem;
//   }

//   @media screen and (max-width: 1116px) {
//     // grid-template-columns: 66rem;
//     grid-template-columns: 1fr;
//     max-width: 66rem;
//   }
//   // @media screen and (max-width: 700px) {
//   //   grid-template-columns: 1fr;
//   //   column-gap: 0rem;
//   // }
// }

// .cd-1st-col {
//   grid-column: 1/2;
// }
// .cd-2nd-col {
//   grid-column: 2/3;
//   // 864px make second col dissappear
//   @media screen and (max-width: 1116px) {
//     display: none;
//   }
// }

.cd-wrapper {
  width: 100%;
  display: flex;
  position: relative;
}

.cd-1st-col {
  width: 66rem;
  max-width: 100%;
}
.cd-2nd-col {
  width: 40.8rem;
  margin-left: 4.6rem;
  @media screen and (max-width: 1146px) {
    margin-left: 1.6rem;
  }
  @media screen and (max-width: 1116px) {
    display: none;
  }
}

.cd-bookingcard-2 {
  width: 100%;
  max-width: 100%;
  @media screen and (min-width: 1116px) {
    display: none;
  }
}
.cd-banner-container {
  // height: 37.125rem;
  width: auto;
  height: auto;
  max-width: 660px;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 40px;
  aspect-ratio: 16/9;
  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
}
.cd-banner {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.cd-no-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 40px;
  background-color: #ecf0f2;
}

.cd-bottom-margin {
  margin-bottom: 6rem;
  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
}

.cd-amenities-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  column-gap: 0.8rem;
  row-gap: 2.4rem;
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.cd-venuecard {
  // overflow: hidden;
  border-radius: 8px;
  border: solid 1px $shade-1;
}

.cd-venuecard-content {
  padding: 3.2rem;
  // border: solid 1px $shade-1;
  background-color: $colour-white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.cd-speaker-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1.6rem;

  @media screen and (max-width: 700px) {
    grid-template-columns: 32.2rem;
  }
  @media screen and (max-width: 354px) {
    grid-template-columns: 1fr;
  }
}

.cd-credits-table {
  width: 100%;
  border-spacing: 0;
  @include avenir-heavy-18;
  thead {
    tr {
      background-color: $shade-1;
      th {
        padding: 16px;
        text-align: left;
        &:last-child {
          text-align: center;
        }
      }
      // first th of every row as there are 2 ths in each row
      & > th:nth-child(2n + 1) {
        width: 60%;
      }
      // second th of every row as there are 3 ths in each row
      & > th:nth-child(2n + 2) {
        width: 40%;
      }
    }
  }
  tbody {
    td {
      padding: 16px;
      text-align: left;
      color: $gray-2;
    }

    tr {
      background-color: $shade-2;

      // every odd row is backcolor white
      &:nth-child(odd) {
        background-color: #fff;
      }

      // first td of every row as there are 2 tds in each row
      & > td:nth-child(2n + 1) {
        width: 60%;
        color: $confemy-black;
      }

      // 2rd td of every row as there are 2 tds in each row
      & > td:nth-child(2n + 2) {
        width: 40%;
        text-align: center;
      }
    }
  }
}

.cd-tickets-table {
  width: 100%;
  border-spacing: 0;
  // table-layout: fixed;
  // word-wrap: break-word;
  @include avenir-heavy-18;
  thead {
    tr {
      background-color: $shade-1;
      th {
        padding: 16px;
        text-align: left;
        // &:last-child {
        //   text-align: center;
        // }
      }
      // first th of every row as there are 3 tds in each row
      & > th:nth-child(3n + 1) {
        width: 50%;
      }
      // second th of every row as there are 3 tds in each row
      & > th:nth-child(3n + 2) {
        width: 20%;
      }

      // 3rd th of every row as there are 3 tds in each row
      & > th:nth-child(3n + 3) {
        width: 30%;
      }
    }
  }
  tbody {
    td {
      padding: 16px;
      text-align: left;
      color: $gray-2;
    }

    tr {
      background-color: $shade-2;

      // every odd row is backcolor white
      &:nth-child(odd) {
        background-color: #fff;
      }

      // first td of every row as there are 3 tds in each row
      & > td:nth-child(3n + 1) {
        width: 50%;
        color: $confemy-black;
      }
      // second td of every row as there are 3 tds in each row
      & > td:nth-child(3n + 2) {
        width: 20%;
      }

      // 3rd td of every row as there are 3 tds in each row
      & > td:nth-child(3n + 3) {
        width: 30%;
      }
    }
  }
}

.cd-tickets-grid-table {
  grid-template-columns: 1fr 1fr auto auto !important;
}

.react-multiple-carousel__arrow {
  background: rgba(#fff, 0.5);
  z-index: 0;

  &:hover {
    background: rgba(#fff, 1);
  }
}

.react-multiple-carousel__arrow::before {
  color: $gray-3;
}

.react-multi-carousel-dot {
  &--active {
    button {
      background: $accent;
    }
  }

  button {
    margin-right: 12px;
    margin-bottom: 12px;
    border: none;

    &:hover:active {
      background-color: $accent;
    }
  }
}

.conf-details-card {
  // width: 40.8rem;
  width: 100%;
  max-width: 40.8rem;
  background-color: #fff;
  padding: 2.4rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 4px 8px 0 rgba(8, 65, 92, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  h4 {
    line-height: 1.2;
    color: $confemy-black;
  }
}

.cd-follow-button {
  margin-top: 0.5rem;
  padding: 0.2rem 1rem;
  color: #08415c;
  border: 2px solid #08415c;
  background-color: white;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.added-docs {
  margin: 4.1rem 0 8rem 0;
}

.speaker-card {
  position: relative;
  border: none;
  border-radius: 4px;
  background-color: $background;
  overflow: hidden;
}

.speaker-card:hover {
  background-color: white;
}

.profile-pic {
  width: 14rem;
  height: 14rem;
  margin: 3rem 9.1rem 2.5rem 9.1rem;
  border-radius: 50%;
}

.cd-1stcol-cardwrap {
  display: none;
}

.bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    padding: 0;
  }

  button {
    width: 20rem;
    height: 4.8rem;
    @media screen and (max-width: 400px) {
      width: 18rem;
    }
  }

  .button {
    padding: 0;
  }
}
