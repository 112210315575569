@import './utility.styles.scss';

// forms

.form-type-1 {
  input[type="text"],
  // input[type="date"],
  input[type="submit"],
  // input[type="datetime-local"],
  // input[type="date"],
  // input[type="time"],
  input[type="number"],
  select,
  input[type="email"],
  input[type="password"] {
    width: 100%;
    display: block;
    padding: 2.4rem 2.4rem 1.6rem;
    font-size: 1.6rem;
    outline: none;
    border-radius: 4px;
    border: solid 2px $shade-1;
    background-color: $colour-white;
    @include caption-1-regular;
    // color: $gray-2;
    color: $confemy-black;

    transition: 0.1s ease-out;

    &:hover {
      border: solid 2px $shade-4;
    }

    &:focus {
      //   padding: 1.6rem 2.4rem;
      border: 2px solid $info;
    }
  }

  textarea {
    width: 100%;
    height: 120px;
    display: block;
    padding: 1.6rem 2.4rem;
    font-size: 1.6rem;
    border-radius: 4px;
    border: solid 2px $shade-1;
    outline: none;
    background-color: $colour-white;
    @include caption-1-regular;
    // color: $gray-2;
    color: $confemy-black;
    transition: 0.1s ease-out;

    &:hover {
      border: solid 3px $shade-4;
    }

    &:focus {
      padding: 10px 24px 10px 24px;
      border: solid 2px $info;
    }
  }

  .input-container {
    margin-bottom: 2.4rem;
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
}

// form type 2

.form-type-2 {
  input[type='text'],
  input[type='submit'],
  input[type='number'],
  select,
  input[type='email'],
  input[type='password'] {
    width: 100%;
    height: 4.8rem;
    display: block;
    padding: 1.6rem 2.4rem 1.6rem 6rem;
    font-size: 1.6rem;
    outline: none;
    border-radius: 4px;
    border: solid 2px $shade-1;
    background-color: $colour-white;
    @include caption-1-regular;
    // color: $gray-2;
    color: $confemy-black;
    transition: 0.1s ease-out;

    &:hover {
      border: solid 2px $shade-4;
    }

    &:focus {
      border: 2px solid $info;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    display: block;
    padding: 1.6rem 2.4rem;
    font-size: 1.6rem;
    border-radius: 4px;
    border: solid 2px $shade-1;
    outline: none;
    background-color: $colour-white;
    @include caption-1-regular;
    // color: $gray-2;
    color: $confemy-black;
    transition: 0.1s ease-out;

    &:hover {
      border: solid 3px $shade-4;
    }

    &:focus {
      padding: 10px 24px 10px 24px;
      border: solid 2px $info;
    }
  }

  .input-container {
    margin-bottom: 2.4rem;
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
}

// form type 3

.form-type-3 {
  input[type='text'],
  input[type='submit'],
  input[type='number'],
  select,
  input[type='email'],
  input[type='password'] {
    width: 100%;
    height: 48px;
    display: block;
    padding: 1rem 2.4rem;
    border-radius: 4px;
    outline: none;
    border: solid 2px $shade-1;
    background-color: $colour-white;
    font-size: 1.6rem;
    @include caption-1-regular;
    // color: $gray-2;
    color: $confemy-black;

    transition: 0.1s ease-out;

    &:hover {
      border-color: $shade-4;
    }

    &:focus {
      border: 2px solid $info;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    display: block;
    padding: 1.6rem 2.4rem;
    font-size: 1.6rem;
    border-radius: 4px;
    border: solid 2px $shade-1;
    outline: none;
    background-color: $colour-white;
    @include caption-1-regular;
    // color: $gray-2;
    color: $confemy-black;
    transition: 0.1s ease-out;

    &:hover {
      border: solid 3px $shade-4;
    }

    &:focus {
      padding: 10px 24px 10px 24px;
      border: solid 2px $info;
    }
  }

  .input-container {
    margin-bottom: 2.4rem;
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
}

.form-type-session {
  input[type='text'],
  input[type='submit'],
  input[type='number'],
  select,
  input[type='email'],
  input[type='password'] {
    width: 100%;
    height: 58px;
    display: block;
    padding: 1rem 2.4rem;
    border-radius: 4px;
    outline: none;
    border: solid 2px $shade-1;
    background-color: $colour-white;
    font-size: 1.6rem;
    @include caption-1-regular;
    // color: $gray-2;
    color: $confemy-black;

    transition: 0.1s ease-out;

    &:hover {
      border-color: $shade-4;
    }

    &:focus {
      border: 2px solid $info;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    display: block;
    padding: 1.6rem 2.4rem;
    font-size: 1.6rem;
    border-radius: 4px;
    border: solid 2px $shade-1;
    outline: none;
    background-color: $colour-white;
    @include caption-1-regular;
    // color: $gray-2;
    color: $confemy-black;
    transition: 0.1s ease-out;

    &:hover {
      border: solid 3px $shade-4;
    }

    &:focus {
      padding: 10px 24px 10px 24px;
      border: solid 2px $info;
    }
  }

  .input-container {
    margin-bottom: 2.4rem;
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
}

// form type 4

.form-type-4 {
  input[type="text"],
  // input[type="date"],
  input[type="submit"],
  // input[type="datetime-local"],
  // input[type="date"],
  // input[type="time"],
  input[type="number"],
  select,
  input[type="email"],
  input[type="password"] {
    width: 100%;
    // height: 6.6rem;
    display: block;
    padding: 2rem;
    // font-size: 1.8rem;
    font-size: 1.6rem;
    // font-size: 2rem;
    outline: none;
    border-radius: 4px;
    border: solid 2px $shade-1;
    background-color: $colour-white;
    // @include caption-1-regular;
    @include avenir-500;
    // color: $confemy-black;

    transition: 0.1s ease-out;

    &:hover {
      border: solid 2px $shade-4;
      // border: solid 2px $primary;
    }

    &:focus {
      //   padding: 1.6rem 2.4rem;
      border: 2px solid $info;
      // border: 2px solid $primary;
    }
    &::placeholder {
      // color: $shade-1;
      color: $gray-2;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: $shade-1;
    }
  }

  textarea {
    width: 100%;
    height: 120px;
    display: block;
    // padding: 1.6rem 2.4rem;
    padding: 1.6rem 2.4rem;
    // font-size: 1.8rem;
    font-size: 1.6rem;
    // font-size: 2rem;
    border-radius: 4px;
    border: solid 2px $shade-1;
    outline: none;
    background-color: $colour-white;
    // @include caption-1-regular;
    @include avenir-500;
    // color: $confemy-black;
    transition: 0.1s ease-out;

    &:hover {
      border: solid 2px $shade-4;
    }

    &:focus {
      // padding: 10px 24px 10px 24px;
      border: solid 2px $info;
    }
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
}

//// material textfield scss, its to be used with form-type-1 class

.material-textfield {
  position: relative;
}

.material-textfield label {
  position: absolute;
  font-size: 1.6rem;
  left: 2.4rem;
  top: 50%;
  transform: translateY(-50%);
  // background-color: $colour-white;
  color: $gray-2;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.material-textfield input:focus + label {
  top: 16px;
  transform: translateY(-50%) scale(0.9);
  border-color: red;
  line-height: 0.9;
}

.material-textfield input:not(:placeholder-shown) + label {
  top: 16px;
  transform: translateY(-50%) scale(0.9);
}

// end of material textfield scss
