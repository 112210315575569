.host-conf-section {
  width: 100%;
  margin: 0 auto;
  max-width: 136rem;
  padding-top: 40px;
  padding-bottom: 72px;
  padding-left: 5.6rem;
  padding-right: 5.6rem;
  @media screen and (max-width: 700px) {
    padding: 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 20px;
  }
}

.host-conf-grid {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 40px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 32px;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.host-conf-text {
  margin: auto 0;
  @media screen and (max-width: 700px) {
    text-align: center;
  }
}

.host-conf-sketch-wrap {
  margin: auto 0;
  @media screen and (max-width: 700px) {
    text-align: center;
  }
}

.host-your-conference-sketch {
  max-width: 100%;
  width: 430px;
  height: 372px;
  margin: 0 auto;
}
