@import "../../utility/utility.styles.scss";

.nosaved-confs-wrap {
  margin: 0px auto;
  width: fit-content;
  max-width: 100%;
  text-align: center;
}
.savedconfs-card {
  width: 100%;
  display: grid;
  grid-template-columns: 282px 1fr auto;
  grid-template-rows: auto;
  background-color: $shade-2;
  border-radius: 0.5rem;
  padding: 2.4rem;
  gap: 2.4rem;
  @media screen and (max-width: 732px) {
    grid-template-columns: 2.2fr 3.8fr auto;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr auto;
    padding: 1.6rem;
  }
}
// sc is saved confs
.sc-banner-container {
  // width: 100%;
  @media screen and (max-width: 600px) {
    width: 100%;
    grid-column: 1/-1;
  }
  @media screen and (max-width: 450px) {
    display: flex;
    justify-content: center;
  }
}

.sc-conf-img-wrap {
  width: auto;
  height: auto;
  aspect-ratio: 16/9;
  max-width: 282px;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
}

.sc-conf-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.savedconfs-title {
  @include avenir-heavy-20;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
  @media screen and (max-width: 732px) {
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.savedconf-button {
  display: inline-block;
  outline: none;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.12s ease-in;
  min-width: 1.6rem;
  padding: 1.6rem 3.2rem;
  border-radius: 4px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.41px;
  text-align: center;
  color: white;

  @media screen and (max-width: 750px) {
    padding: 1rem 1rem;
  }
}

.savedConf-button-primary {
  background: #08415c;
  border-color: #08415c;
}
