@import '../../utility//utility.styles.scss';

.ab-card {
  width: 100%;
  padding: 20px;
  min-height: 27.3rem;
  border-radius: 0.8rem;
  // margin-bottom: 2.8rem;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(52, 40, 40, 0.08);
  cursor: pointer;
  overflow-wrap: break-word;
  background-color: #fff;
  &:hover {
    background-color: $background;
  }
}

.ab-card-mrgin {
  margin-left: 120px;
  @media screen and (max-width: 700px) {
    margin-left: 0;
  }
}

.ab-card-header {
  width: 100%;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 16px;
  align-items: center;
  // border: 1px solid black;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}

.ab-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}

.ab-button {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  // align-items: center;
  justify-content: flex-start;
  button {
    width: 120px; /* Increase the width here */
  }
  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    flex-direction: row;
    // align-content: flex-start;
  }
  @media screen and (max-width: 400px) {
    button {
      flex-direction: row;
      flex-wrap: wrap;
      // align-content: flex-start;
      width: 100px;
      padding: 4px;
      min-width: 0;
      height: 52px;
    }
  }
}

.ab-card-body {
  width: 100%;
  // margin-bottom: 24px;
}
.ab-card-footer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 256px;
  gap: 16px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}

.ticket-avenir-roman-18 {
  font-family: Avenir-Roman;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.01px;
  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
}

.user-ticket-btn {
  background: $primary;
  color: white;
  height: 4.8rem;
}

.user-ticket-resend {
  border-radius: 0 0rem 0rem 0.8rem;
}
.user-ticket-print {
  border-radius: 0 0rem 0.8rem 0rem;
}

.print-ticket-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  word-wrap: break-word;
  grid-template-rows: 50px;
  @media screen and (max-width: 685px) {
    display: block;
  }
}
.print-ticket {
  text-overflow: hidden;
  width: 65%;
  margin: 0 auto;
  @media screen and (max-width: 685px) {
    margin: 0 8px;
  }
}
.print-ticket-container {
  // border: 2px solid black;
  padding: 24px 56px;
}
.print-ticket-content {
  grid-column: 2 / span 4;
}
.print-ticket-barcode {
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (max-width: 1200px) {
    grid-column-start: 1;
    grid-column-end: 4;
    display: none;
  }
}

.print-ticket-text-1 {
  grid-column: 2/3;
}
.print-ticket-text-2 {
  grid-column: 3/4;
}

.preview-text {
  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.print-ticket-receipt-conatiner {
  padding: 92px;
  @media screen and (max-width: 710px) {
    padding: 92px 15px;
  }
}

.print-receipt-text {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 580px) {
    flex-direction: column;
  }
}

.print-border {
  border: solid 2px $shade-1;
  padding: 2rem;
  margin-bottom: 78px;
}
